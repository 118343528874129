import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Empty } from 'antd'
import { getBudgetSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendBarChartsOption, formatMillion } from './util'
import { formatNum } from '@/common/util'
export default function TrendDivisionAndYear(props) {
  const { setShowEmpty, unit } = props
  const trend = useRef(null)
  const { updateCharts } = useCharts(trend)
  /**
   * catogeryname 区分的类别名  比如 fa fbp
   * valname 具体显示的值 比如budget
   */
  const formatBarData = (data, catogeryname, valname) => {
    const xData = [...new Set(data.map((i) => i.year))] // [2021 ,2022]

    const ynames = [...new Set(data.map((i) => i[catogeryname]))] // [fa,fbp]
    const yData = [] //[[fa-21-budget,fa-22-budget,name:fa],[fbp-21-budget,fbp-22-budget,name:fbp]]
    ynames.forEach((name) => {
      const res = []
      xData.forEach((year) => {
        let item = data.filter((i) => i.year === year && i[catogeryname] === name)[0]
        //  console.log(item[valname],unit,)
        res.push(item ? formatNum(item[valname], unit, 0) : 0)
      })
      yData.push(res)
    })

    return {
      xData,
      yData,
      ynames,
    }
  }

  useEffect(() => {
    getBudgetSum({
      title: '第一张柱状图',
      filters: [
        {
          field: 'mediachannel',
          operator: 'IN',
          values: ['Search'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['PPC', 'Brandzone'],
        },
      ],
      fields: ['budget'],
      groups: ['year', 'division'],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
        {
          field: 'division',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      if (res?.length) {
        setShowEmpty(false)
      } else {
        return setShowEmpty(true)
      }
      const formatdata = formatBarData(res, 'division', 'budgetamount')

      updateCharts(getTrendBarChartsOption(formatdata, unit))
    })
  }, [unit])

  return (
    <div className="trend-wrap">
      <div ref={trend} style={{ width: '100%', height: '320px' }}></div>
    </div>
  )
}
