import styles from './index.module.less'
import moment from 'moment'

import { Button, Select, DatePicker, Space, Modal, message, Collapse } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { getTimestamp, specialColumns, numberWithCommas } from '@/common/util'
import { getSum, getSum1_2, downTable1_2, getSumTable } from '@/api/home'
import { divisionOption, categoryOption } from '@/common/vars'
import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import ReportFields from '@/components/ReportFields'
const { Panel } = Collapse
const { RangePicker } = DatePicker
const Filter = (props) => {
  const { wrap } = styles
  const {
    headerLine = false,
    minTitleWidth = '270px',
    children,
    setmonths,
    setyears,
    yearsopt,
    labelWidth,
    title,
    dates,
    setDates,
    fields,
    setFields,
    mark,
    defaultOption,
    myDimensionOption,
    myIndexOption,
    data,
    sorter,
    setData,
    collapsed,
  } = props
  //

  // const getMonthDate = () => {
  //   const now = new Date()

  //   const year = now.getFullYear()
  //   const month = ('0' + (now.getMonth() + 1)).slice(-2)
  //   const day = ('0' + now.getDate()).slice(-2)
  //   return [
  //     moment(`${year}-${month}-01`, dateFormat),
  //     moment(`${year}-${month}-${day}`, dateFormat),
  //   ]
  // }

  const dateFormat = 'YYYY-MM-DD'
  const [mydates, setmydates] = useState()
  const [indexFields, setIndexFields] = useState([]) //下拉自定义选项,指标
  const collectRef = React.createRef()

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const borderBottom = headerLine ? '1px solid #F0F0F0' : 'none'
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const [cost, setCost] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const myfields = myDimensionOption
    .map((it) => {
      return it.value
    })
    .concat(
      myIndexOption.map((it) => {
        return it.value
      })
    )
  const handleCampaignnameChange = (val) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) return setCampaignname(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setCampaignname(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setCampaignname(['all']) //由其他转为默认
    return setCampaignname(val)
  }

  const handleFieldsChange = (val) => {
    if (val.length === 0) {
      message.destroy()
      message.info('请至少保留一项指标')
    } else {
      setIndexFields(val)
      let list = fields.filter((it) => {
        return !(it.type === 2 && !val.includes(it.value))
      })
      return setFields(list)
    }
  }
  const [divHeight, setdivHeight] = useState()
  const [scrollTop, setScrollTop] = useState()

  useEffect((e) => {
    const box = document.getElementsByName('filter')
    if (box[0]) setdivHeight(box[0].offsetHeight)
    getUpdateTime()
  }, [])
  useEffect(
    (e) => {
      async function fetchData() {
        await setIndexFields(
          fields
            .filter((it) => {
              return it.type === 2
            })
            .map((it) => {
              return it.value
            })
        )
        await setOpt()
      }
      fetchData()
      const box = document.getElementsByName('filter')
      if (box[0]) setdivHeight(box[0].offsetHeight)
    },
    [fields]
  )
  const [media, setMedia] = useState(['all'])
  const [mediaOpt, setMediaOpt] = useState([])
  const [mediaformat, setMediaformat] = useState(['all'])
  const [mediaformatOpt, setMediaformatOpt] = useState([])
  const [divisioncorp, setDivisioncorp] = useState(['all'])
  const [divisioncorpOpt, setDivisioncorpOpt] = useState([])
  const [categorycorp, setCategorycorp] = useState(['all'])
  const [categorycorpOpt, setCategorycorpOpt] = useState([])
  const [campaignname, setCampaignname] = useState(['all'])
  const [campaignnameOpt, setCampaignnameOpt] = useState([])
  const [productline, setProductline] = useState(['all'])
  const [productlineOpt, setProductlineOpt] = useState([])
  const [assets, setAssets] = useState(['all'])
  const [assetsOpt, setAssetsOpt] = useState([])
  const [category, setCategory] = useState(['all'])
  const [categoryOpt, setCategoryOpt] = useState([])
  const [ta2, setTa2] = useState(['all'])
  const [ta2Opt, setTa2Opt] = useState([])
  const [target1, setTarget1] = useState(['all'])
  const [target1Opt, setTarget1Opt] = useState([])
  const [period, setPeriod] = useState(['all'])
  const [periodOpt, setPeriodOpt] = useState([])
  const [assettype, setAssettype] = useState(['all'])
  const [assettypeOpt, setAssettypeOpt] = useState([])
  const [subcategory, setSubcategory] = useState(['all'])
  const [subcategoryOpt, setSubcategoryOpt] = useState([])
  const [placement, setPlacement] = useState(['all'])
  const [placementOpt, setPlacementOpt] = useState([])
  const [close, setClose] = useState(false)
  const [content, setContent] = useState(['all'])
  const [contentOpt, setContentOpt] = useState([])

  const searchCondition = [
    { name: 'media', opt: mediaOpt, setOpt: setMediaOpt, value: media, setVal: setMedia },
    {
      name: 'mediaformat',
      opt: mediaformatOpt,
      setOpt: setMediaformatOpt,
      value: mediaformat,
      setVal: setMediaformat,
    },
    {
      name: 'divisioncorp',
      opt: divisioncorpOpt,
      setOpt: setDivisioncorpOpt,
      value: divisioncorp,
      setVal: setDivisioncorp,
    },
    {
      name: 'categorycorp',
      opt: categorycorpOpt,
      setOpt: setCategorycorpOpt,
      value: categorycorp,
      setVal: setCategorycorp,
    },
    {
      name: 'campaignname',
      opt: campaignnameOpt,
      setOpt: setCampaignnameOpt,
      value: campaignname,
      setVal: setCampaignname,
    },
    {
      name: 'productline',
      opt: productlineOpt,
      setOpt: setProductlineOpt,
      value: productline,
      setVal: setProductline,
    },
    { name: 'assets', opt: assetsOpt, setOpt: setAssetsOpt, value: assets, setVal: setAssets },
    {
      name: 'category',
      opt: categoryOpt,
      setOpt: setCategoryOpt,
      value: category,
      setVal: setCategory,
    },
    { name: 'ta2', opt: ta2Opt, setOpt: setTa2Opt, value: ta2, setVal: setTa2 },
    { name: 'target1', opt: target1Opt, setOpt: setTarget1Opt, value: target1, setVal: setTarget1 },
    { name: 'period', opt: periodOpt, setOpt: setPeriodOpt, value: period, setVal: setPeriod },
    {
      name: 'assettype',
      opt: assettypeOpt,
      setOpt: setAssettypeOpt,
      value: assettype,
      setVal: setAssettype,
    },
    {
      name: 'subcategory',
      opt: subcategoryOpt,
      setOpt: setSubcategoryOpt,
      value: subcategory,
      setVal: setSubcategory,
    },
    {
      name: 'placement',
      opt: placementOpt,
      setOpt: setPlacementOpt,
      value: placement,
      setVal: setPlacement,
    },
    { name: 'content', opt: contentOpt, setOpt: setContentOpt, value: content, setVal: setContent },
  ]
  useEffect(() => {
    //  if(!mediaformatOpt.length||!productlineOpt.length||!subcategoryOpt.length) return
    searchChange()
  }, [
    dates,
    mediaOpt,
    mediaformatOpt,
    divisioncorpOpt,
    categorycorpOpt,
    campaignnameOpt,
    productlineOpt,
    assetsOpt,
    categoryOpt,
    ta2Opt,
    target1Opt,
    periodOpt,
    assettypeOpt,
    subcategoryOpt,
    placementOpt,
    contentOpt,
    media,
    mediaformat,
    divisioncorp,
    categorycorp,
    campaignname,
    productline,
    assets,
    category,
    ta2Opt,
    target1,
    period,
    assettype,
    subcategory,
    placement,
    content,
    fields,
  ])

  const getCondition = () => {
    let myFields = fields
      .filter((it) => {
        return it.type === 1
      })
      .map((it) => {
        return it.value
      })
    let conditionData = searchCondition.filter((it) => {
      return myFields.includes(it.name)
    })
    let conditionList = []
    conditionData.forEach((item) => {
      let values = item.value.includes('all')
        ? item.opt.length > 1
          ? item.opt.slice(1).map((i) => i.value)
          : ['']
        : item.value

      if (values.join('').length > 0) {
        conditionList.push({
          field: item.name,
          operator: 'IN',
          values,
        })
      }
    })
    if (dates && dates.join('').length > 0) {
      conditionList.push({
        field: 'dateperiod',
        operator: 'IN',
        values: [dates.join(',')],
      })
    }
    if (mark === 'momentsRTBWechat') {
      conditionList.push({
        field: 'mediagroup',
        operator: 'IN',
        values: ['Tencent'],
      })
      conditionList.push({
        field: 'mediaformat',
        operator: 'IN',
        values: ['Moments RTB'],
      })
    }
    if (mark === 'GDFeedsRed') {
      conditionList.push({
        field: 'mediaformat',
        operator: 'IN',
        values: ['GD Feeds', 'RTB Feeds'],
      })
      conditionList.push({
        field: 'mediagroup ',
        operator: 'IN',
        values: ['Red'],
      })
    }
    if (mark === 'channelFeedsWechat') {
      conditionList.push({
        field: 'mediagroup',
        operator: 'IN',
        values: ['Tencent'],
      })
      conditionList.push({
        field: 'mediaformat',
        operator: 'IN',
        values: ['Channel Feeds'],
      })
    }

    return conditionList
  }

  const searchChange = () => {
    let filters = getCondition()
    if (!filters.length || !fields.length) return
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].values.length === 0) return
    }
    let myfields = fields.map((it) => {
      return it.value
    })
    myfields.push('previewurl')
    let data = {
      fields: myfields,
      groups: fields
        .filter((it) => {
          return it.type === 1 && !specialColumns.includes(it.value)
        })
        .map((it) => {
          return it.value
        }),
      filters,
      title: '获取table数据',
    }
    getSumTable(data).then((res) => {
      setData(res || undefined)
    })
  }
  const handleChange = (val, setFun) => {
    if (!val.length) {
      setFun(() => ['all'])
    } else {
      const index = val.indexOf('all')
      if (index === 0 && val.length > 0) setFun(() => val.slice(1)) //由默认转为其他
      else if (index === val.length - 1 && val.length > 0) setFun(() => ['all']) //由其他转为默认
      else setFun(() => val)
    }
  }

  const onBlur = () => {
    // searchChange()
  }

  const setOpt = async () => {
    await searchCondition.forEach(async (item) => {
      if (showSelect(item.name)) {
        if (item.opt.length === 0) await getOpt(item.name, item.setOpt)
        else item.setVal(['all'])
      }
    })
  }

  const getOpt = async (val, fun) => {
    let specialTtems = {
      divisioncorp: divisionOption,
      categorycorp: categoryOption,
      category: categoryOption,
    }
    if (specialTtems[val]) {
      fun(() => [
        {
          label: '全部',
          value: 'all',
        },
        ...specialTtems[val].map((i) => ({ label: i, value: i })),
      ])
    } else {
      await getSum1_2({
        groups: [val],
      }).then((res) => {
        fun(() => [
          {
            label: '全部',
            value: 'all',
          },
          ...res
            .filter((i) => {
              return i[val] != '' && i[val] != '-'
            })
            .map((i) => ({ label: i[val], value: i[val] })),
        ])
      })
    }
  }
  const onMonthChange = (checkedValues) => {
    setmonths(checkedValues)
    setCheckAll(checkedValues.length === monthOptions.length)
  }
  const onDateChange = (val, val2) => {
    setmydates(val)
    setDates(val2)
  }
  const onYearChange = (checkedValues) => {
    setyears(checkedValues)
    setCheckYearAll(checkedValues.length === yearsopt.length)
  }
  const [checkAll, setCheckAll] = useState(true)
  const [checkYearAll, setCheckYearAll] = useState(yearsopt.length === 1 ? true : false)

  const monthOptions = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const [updatetime, setupdatetime] = useState('')
  const getUpdateTime = () => {
    getSum1_2({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
      sorts: [{ field: 'maxyearandmonth', type: 'DESC' }],
    }).then((res) => {
      setupdatetime(res[0].maxyearandmonth)

      if (res[0].maxyearandmonth) {
        setDates([res[0].maxyearandmonth, res[0].maxyearandmonth])
        setmydates([
          moment(res[0].maxyearandmonth, dateFormat),
          moment(res[0].maxyearandmonth, dateFormat),
        ])
      }
    })
  }

  //  日期赋值 updatetime

  const onCheckAllChange = (e) => {
    setmonths(e.target.checked ? monthOptions : [])
    setCheckAll(e.target.checked)
  }
  const onCheckAllYearChange = (e) => {
    setyears(e.target.checked ? yearsopt : [])
    setCheckYearAll(e.target.checked)
  }

  const showSelect = (val) => {
    //是否展示某个维度
    if (fields && fields.length > 0) {
      let list = fields.map((it) => {
        return it.value
      })
      return list.includes(val)
    } else return false
  }

  const scrollChange = () => {
    // 监听滚动条距离顶部距离
    if (document.getElementsByClassName('ant-layout-content')?.[0]) {
      setScrollTop(document.getElementsByClassName('ant-layout-content')[0].scrollTop)
      const box = document.getElementsByName('filter')
      if (box[0]) setdivHeight(box[0].offsetHeight)
    }
    //  console.log((document.getElementsByClassName('ant-layout-content')?.[0]))
    // console.log(document.documentElement.scrollTop,document.body.scrollTop)
    // 监听滚动条距离左部距离

    //这里有一个坑如果在页面内给某个div添加了滚动条用上面方法获取的数据会一直为0 可以用下边方法
    // 监听div内滚动条距离顶部距离
    // console.log(document.getElementById('bodybox')?.scrollTop)
    // 监听div内滚动条距离左部距离
    //  console.log(document.getElementById('bodybox')?.scrollLeft)
  }

  useEffect(() => {
    // 滚动条滚动时触发
    window.addEventListener('scroll', scrollChange, true)
    scrollChange()
    return () => {
      window.removeEventListener('scroll', scrollChange, false)
    }
  }, [])
  const downLoadTable = () => {
    downTable1_2(
      {
        fields: fields.map((it) => {
          return it.value
        }),
        groups: fields
          .filter((it) => {
            return it.type === 1
          })
          .map((it) => {
            return it.value
          }),
        filters: getCondition(true),
        sorts: sorter && sorter.type ? [sorter] : undefined,
      },
      { responseType: 'blob' }
    ).then((res) => {
      const data = res.data
      const link = document.createElement('a')
      const contentType = 'application/vnd.ms-excel'
      const blob = new Blob([data], { type: contentType })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', getTimestamp() + `.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }
  const changeClose = () => {
    setClose(!close)
  }
  return (
    <div className="myfilter">
      <div
        className={wrap}
        name="filter"
        style={{
          position: 'fixed',
          left: collapsed ? '94px' : '269px',
          top: mark === 'crossPerformance' ? '58px' : scrollTop > 100 ? '58px' : '98px',
          right: '26px',
          backgroundColor: '#fff',
          zIndex: 90,
        }}
      >
        <div className="header" style={{ borderBottom }}>
          <div className="title" style={{ minWidth: minTitleWidth }}>
            {title}
            <div className="closeTool" onClick={changeClose}>
              <ArrowUpOutlined style={{ display: close ? 'none' : 'block' }} />{' '}
              <ArrowDownOutlined style={{ display: !close ? 'none' : 'block' }} />
            </div>
            <span>Data update to {updatetime}</span>
          </div>
        </div>

        <div className="yearmonthbudget" style={{ display: close ? 'none' : 'block' }}>
          <div className="line tool">
            <div>维度：</div>
            <div>
              <ReportFields
                fields={fields}
                setFields={setFields}
                mark={mark}
                defaultOption={defaultOption}
                myDimensionOption={myDimensionOption}
                myIndexOption={myIndexOption}
              ></ReportFields>
            </div>
          </div>
          <div className="line">
            <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
              选择日期：
            </span>
            <RangePicker
              value={mydates}
              picker="month"
              onChange={onDateChange}
              onBlur={() => console.log('blur has been triggered')}
            />
          </div>

          <div className="line2">
            <div style={{ display: showSelect('media') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Media：
              </span>
              <Select
                maxTagCount={5}
                options={mediaOpt}
                value={media}
                onBlur={onBlur}
                onChange={(e) => {
                  handleChange(e, setMedia)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('mediaformat') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Media Format：
              </span>
              <Select
                maxTagCount={5}
                options={mediaformatOpt}
                value={mediaformat}
                onBlur={onBlur}
                onChange={(e) => {
                  handleChange(e, setMediaformat)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('divisioncorp') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Division：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={divisioncorpOpt}
                value={divisioncorp}
                onChange={(e) => {
                  handleChange(e, setDivisioncorp)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('categorycorp') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Category：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={categorycorpOpt}
                value={categorycorp}
                onChange={(e) => {
                  handleChange(e, setCategorycorp)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('campaignname') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Campaign Name：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={campaignnameOpt}
                value={campaignname}
                onChange={(e) => {
                  handleChange(e, setCampaignname)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('productline') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Product Line：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={productlineOpt}
                value={productline}
                onChange={(e) => {
                  handleChange(e, setProductline)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('assets') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Assets：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={assetsOpt}
                value={assets}
                onChange={(e) => {
                  handleChange(e, setAssets)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('category') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Category：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={categoryOpt}
                value={category}
                onChange={(e) => {
                  handleChange(e, setCategory)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('ta2') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                TA(Core/Re/Po)：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={ta2Opt}
                value={ta2}
                onChange={(e) => {
                  handleChange(e, setTa2)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('target1') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                TA(Target 1)：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={target1Opt}
                value={target1}
                onChange={(e) => {
                  handleChange(e, setTarget1)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            {/* <div style={{ display: showSelect('startdate') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Start Date：
              </span>
              <Select
                maxTagCount={5}
                options={campaignnameOpt}
                value={campaignname}
                onChange={handleCampaignnameChange}
                style={{ width: '288px' }}
                mode={'multiple'}
              />
            </div> */}
            <div style={{ display: showSelect('period') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Period：
              </span>
              <Select
                onBlur={onBlur}
                maxTagCount={5}
                options={periodOpt}
                value={period}
                onChange={(e) => {
                  handleChange(e, setPeriod)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('assettype') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Asset type：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={assettypeOpt}
                value={assettype}
                onChange={(e) => {
                  handleChange(e, setAssettype)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('subcategory') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Product Line(SubCategory)：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={subcategoryOpt}
                value={subcategory}
                onChange={(e) => {
                  handleChange(e, setSubcategory)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('placement') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Placement：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={placementOpt}
                value={placement}
                onChange={(e) => {
                  handleChange(e, setPlacement)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
            <div style={{ display: showSelect('content') ? 'block' : 'none' }}>
              <span className="label text-right" style={{ width: labelWidth ? labelWidth : null }}>
                Person/Product：
              </span>
              <Select
                maxTagCount={5}
                onBlur={onBlur}
                options={contentOpt}
                value={content}
                onChange={(e) => {
                  handleChange(e, setContent)
                }}
                style={{ width: '288px', marginRight: '10px' }}
                mode={'multiple'}
              />
            </div>
          </div>
          <div className="line tool" style={{ paddingBottom: '8px' }}>
            <div>
              指标：
              <Select
                options={fields.filter((it) => {
                  return it.type === 2
                })}
                value={indexFields}
                onChange={handleFieldsChange}
                style={{ maxWidth: '800px', minWidth: '600px' }}
                mode={'multiple'}
              />
            </div>
            <div>
              <Button type="text" onClick={downLoadTable}>
                <VerticalAlignBottomOutlined style={{ fontSize: '18px' }} />
              </Button>
            </div>
          </div>
          {children}
        </div>
      </div>
      <div style={{ height: close ? '58px' : divHeight + 'px' }}></div>
    </div>
  )
}
export default connect((state) => ({
  yearsopt: state.years.slice().reverse(),
  collapsed: state.collapsed,
}))(Filter)
