import React, { ReactElement, useEffect, useRef, useState, require } from 'react'
import { getPerformanceSum } from '@/api/home'
import { Table, Radio, Button } from 'antd'
import { addtablekey } from './util'
import { formatNum } from '@/common/util'
import { divisionOption } from '@/common/vars'

import ExportJsonExcel from 'js-export-excel'

import { VerticalAlignBottomOutlined, QuestionCircleOutlined } from '@ant-design/icons'
export default function TreeTable(props) {
  const {
    unit,
    division,
    years,
    get,
    category,
    months,
    productLine,
    campaignname,
    mediaformat,
    media,
    asset,
    mediaGroup,
  } = props
  const [data, setData] = useState([])
  const formatTableNum = (num) => {
    if (!num) return 0
    return numberWithCommas(num)
  }
  const numberWithCommas = (x) => {
    let y = x.toString().split('.')

    return y[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (y.length > 1 ? '.' + y[1] : '')
  }
  const exportToExcel = () => {
    let c = columns()

    let sheetFilter = c.map((i) => {
      return i.dataIndex
    })
    let option = {}
    option.fileName = (group === 'target1' ? 'TA' : 'Asset') + '(Core/Retargeting/Potential)'
    option.datas = [
      {
        sheetData: data,
        sheetName: '-', //'unit(' + unit + ')',
        sheetFilter: sheetFilter,
        sheetHeader: c.map((i) => {
          return i.title
        }),
        columnWidths: c.map((i) => {
          return i.width / 10
        }),
      },
    ]
    var toExcel = new ExportJsonExcel(option) //new
    toExcel.saveExcel() //保存
  }
  const specialNumber = (fieldName, v, unit) => {
    let v_ = v
    let special1 = ['ctr', 'er_wechat', 'er_post', 'cvr_wechat', 'cvr_search', 'webaddtocart_click'] //保留两位小数带百分比1111
    let special2 = [
      'cpm',
      'cpc',
      'roi',
      't0ysrevenuema',
      't7ysrevenuema',
      't15ysrevenuema',
      'cpe_post',
    ] //保留两位小数
    if (special1.includes(fieldName)) return Number(v_) ? v_.toFixed(2) + '%' : '-'
    else if (special2.includes(fieldName)) return Number(v_) ? v_.toFixed(2) : '-'
    else return unit ? numberWithCommas(formatNum(v_, unit, 0)) : numberWithCommas(v)
  }
  const columnFieldList = {
    headList: {
      target1: [{ title: 'TA定向', dataIndex: 'target1', key: 'target1', width: 200 }],
      assets: [
        { title: 'Asset', dataIndex: 'assets', key: 'assets', width: 100 },
        {
          title: 'Previewurl',
          dataIndex: 'previewurl',
          width: 100,
          key: 'previewurl',
          render(val) {
            // let fun =fu((val)=>{

            // })
            return val ? (
              <a target="_blank" style={{ color: 'blue' }} href={val} rel="noreferrer">
                预览
              </a>
            ) : (
              '--'
            )
            // <span onClick={fun(val)}>预览</span>
          },
        },
      ],
    },
    otherList: [
      {
        title: 'IMP',
        dataIndex: 'impression',
        key: 'impression',
        tag: 'all|Red',
        width: 100,
        sorter: (a, b) => {
          return a.impression - b.impression
        },
        render(val) {
          return specialNumber('impression', formatNum(val, unit, 0))
        },
      },
      {
        title: 'Click',
        dataIndex: 'click',
        key: 'click',
        tag: 'all|Red',
        width: 100,
        sorter: (a, b) => {
          return a.click - b.click
        },
        render(val) {
          return formatTableNum(formatNum(val, unit, 0))
        },
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        tag: 'all|Tencent',
        width: 100,
        sorter: (a, b) => {
          return a.cost - b.cost
        },
        render(val) {
          return specialNumber('cost', formatNum(val, unit, 0))
        },
      },
      {
        title: 'CTR',
        dataIndex: 'ctr',
        key: 'ctr',
        tag: 'all|Tencent|Red',
        width: 100,
        sorter: (a, b) => {
          return (parseFloat(a.ctr) || 0) - (parseFloat(b.ctr) || 0)
        },
        render(val) {
          return specialNumber('ctr', val)
        },
      },
      {
        title: 'Order ROI',
        dataIndex: 'roi',
        key: 'roi',
        tag: 'all|Tencent',
        width: 150,
        sorter: (a, b) => {
          return (parseFloat(a.roi) || 0) - (parseFloat(b.roi) || 0)
        },
        render(val) {
          return specialNumber('roi', val)
        },
      },
      {
        title: 'ATC%',
        dataIndex: 'webaddtocart_click',
        key: 'webaddtocart_click',
        tag: 'Tencent',
        width: 100,
        sorter: (a, b) => {
          return a.webaddtocart_click - b.webaddtocart_click
        },
        render(val) {
          return specialNumber('webaddtocart_click', val)
        },
      },
      {
        title: 'ER-POST',
        dataIndex: 'er_post',
        key: 'er_post',
        tag: 'Red',
        width: 100,
        sorter: (a, b) => {
          return a.er_post - b.er_post
        },
        render(val) {
          return specialNumber('er_post', val)
        },
      },
    ],
  }
  const [group, setGroup] = useState('assets')
  const onChange2 = ({ target: { value } }) => {
    setGroup(value)
  }
  const radioOption = [
    {
      label: 'Asset',
      value: 'assets',
    },
    {
      label: 'TA(Target1)',
      value: 'target1',
    },
  ]
  const columns = () => {
    let m = mediaGroup
    let columnsList = columnFieldList.headList[group].concat(
      columnFieldList.otherList.filter((i) => {
        if (m.length === 1 && ['Tencent', 'Red'].includes(m[0])) return i.tag.indexOf(m) >= 0
        else return i.tag.indexOf('all') >= 0
      })
    )
    return columnsList
  }
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const handleData = (res) => {
    return res.map((r, index) => {
      r['key'] = index
      //  r['impression'] = specialNumber('impression', formatNum(r['impression'], unit, 0))
      // r['click'] = formatTableNum(formatNum(r['click'], unit, 0))
      // r['cost'] = specialNumber('cost', formatNum(r['cost'], unit, 0))
      // r['ctr'] = specialNumber('ctr', r['ctr'])
      // r['roi'] = specialNumber('roi', r['roi'])
      // r['webaddtocart_click'] = specialNumber('webaddtocart_click', r['webaddtocart_click'])

      return r
    })
  }
  useEffect(() => {
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Search'],
      },
      {
        field: group,
        operator: 'NOT_IN',
        values: ['', ' ', '-'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productline',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })
    let f = columns().map((i) => {
      return i.dataIndex
    })
    getPerformanceSum({
      fields: f,
      groups: group == 'target1' ? [group] : [group, 'previewurl'],
      filters,
      sorts: [
        {
          field: group,
          type: 'ASC',
        },
      ],
    }).then((res) => {
      let data2 = handleData(res)
      setData(data2)
    })
  }, [years, months, get, group, unit])

  return (
    <div className="trend-wrap">
      <div className="title">
        {(group === 'target1' ? 'TA' : 'Asset') + '(Core/Retargeting/Potential)'}
      </div>
      <div className="tool">
        <div style={{ width: '170px' }}>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            value={group}
            size="small"
            optionType="button"
          />
        </div>
        <div>
          <Button type="text" onClick={exportToExcel} className="downBtn">
            <VerticalAlignBottomOutlined style={{ fontSize: '18px' }} />
          </Button>
        </div>
      </div>
      <Table columns={columns()} dataSource={data} scroll={{ y: 400 }} pagination={false} />
    </div>
  )
}
