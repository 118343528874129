import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { getPerformanceSum } from '@/api/home'
import { Table } from 'antd'
import { addtablekey } from './util'
import { formatNum, numberWithCommas } from '@/common/util'
import { divisionOption } from '@/common/vars'
import { CheckOutlined } from '@ant-design/icons'
import baidu from '@/assets/mediaImg/baidu.png'
import red from '@/assets/mediaImg/red.png'
import union from '@/assets/mediaImg/union.png'
import wechat from '@/assets/mediaImg/wechat.png'
import baidu_c from '@/assets/mediaImg/baidu_c.png'
import red_c from '@/assets/mediaImg/red_c.png'
import union_c from '@/assets/mediaImg/union_c.png'
import wechat_c from '@/assets/mediaImg/wechat_c.png'
import wy_c from '@/assets/mediaImg/wy_c.png'
import wy from '@/assets/mediaImg/wy.png'
import { VerticalAlignBottomOutlined, QuestionCircleOutlined } from '@ant-design/icons'

export default function TreeTable(props) {
  const {
    get,
    division,
    years,
    yearsopt,
    category,
    productLine,
    campaignname,
    unit,
    setMediagroup,
    mediagroup,
    media,
    mediaformat,
    asset,
    months,
  } = props
  const [data, setData] = useState([])
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const mediaImgList = {
    Tencent: { img: wechat, img_c: wechat_c, mediaFormat: ['Moments RTB', 'Channel Feeds'] },
    Red: { img: red, img_c: red_c, mediaFormat: ['RTB Feeds', 'GD Feeds'] },
    Baidu: { img: baidu, img_c: baidu_c, mediaFormat: ['App Opening Page'] },

    'Union Pay Programmatic': { img: union, img_c: union_c, mediaFormat: ['App Opening Page'] },
    //  Netease: { img: wy, img_c: wy_c, mediaFormat: ['App Opening Page'] },
  }
  const fieldList = {
    Cost: 'cost',
    IMP: 'imp',
    Click: 'click',
    CPM: 'cpm',
    CTR: 'ctr',
  }

  const handelCheckMedia = (val) => {
    setMediagroup(mediagroup[0] !== val ? [val] : [])
  }

  const columns = () => {
    let columnsList = [
      {
        title: '',
        dataIndex: 'field',
        key: 'field',
        render(val) {
          return <div style={{ color: '#108ee9' }}>{val}</div>
        },
      },
    ]
    let i = 1
    for (let key in mediaImgList) {
      let column = {
        title: (
          <div
            onClick={() => {
              handelCheckMedia(key)
            }}
          >
            {mediaImgList[key]['img'] ? (
              <div style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
                <img
                  src={mediaImgList[key][mediagroup[0] === key ? 'img_c' : 'img']}
                  alt=""
                  style={{ width: '48px' }}
                />{' '}
              </div>
            ) : (
              key
            )}
          </div>
        ),
        dataIndex: 'field' + i,
        key: 'field' + i,
      }
      i++
      columnsList.push(column)
    }
    return columnsList
  }

  useEffect(() => {
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Search'],
      },
      {
        field: 'mediagroup',
        operator: 'NOT_IN',
        values: ['', ' ', '-'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!mediagroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediagroup,
      })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })

    getPerformanceSum({
      fields: ['mediagroup', 'cost', 'imp', 'click', 'cpm', 'ctr'],
      groups: ['mediagroup'],
      filters,
      sorts: [
        {
          field: 'mediagroup',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      let data = handleData(res)

      // addtablekey(res)
      setData(data)
      // console.log(res)
    })
  }, [division, years, months, get, unit])

  const handleData = (res) => {
    let list = []
    for (let key in fieldList) {
      let item = { field: key, key }
      let i = 1
      for (let key2 in mediaImgList) {
        let citem = res.filter((i) => {
          return i.mediagroup.indexOf(key2) >= 0
        })

        item['field' + i] = citem[0]
          ? ['ctr', 'cpm'].includes(fieldList[key])
            ? numberWithCommas(citem[0][fieldList[key]]) + (fieldList[key] === 'ctr' ? '%' : '')
            : numberWithCommas(formatNum(citem[0][fieldList[key]], unit, 0))
          : 0
        i++
      }
      list.push(item)
    }
    return list
  }
  return (
    <div className="trend-wrap">
      <Table columns={columns()} dataSource={data} pagination={false} />
    </div>
  )
}
