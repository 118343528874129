import Card from '@/components/Card'
import CombineDate from '@/components/combineDate'
import { useEffect, useState } from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import TrendPerformanceMonth from './trendPerformanceMonth'
import TrendPerformanceMonth2 from './trendPerformanceMonth2'
import TrendLineProd from './trend-line-prod'
import TrendLineProd2 from './trend-line-prod2'
import { Select, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { formatmonth } from './util'
import styles from './index.module.less'
import { getSum1_2, getPerformanceSum2 } from '@/api/home'
const Performance = (props) => {
  const { currentYear, yearsOpt, preYear, division, unit } = props
  const { wrap } = styles
  // const [divisionOpts, setDivisionOpts] = useState([])
  // 这里产品要求写死 左边3 右边2 不走请求

  const monthopt = [{ label: '全部', value: 'all' }].concat(
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => ({
      label: formatmonth(i),
      value: i,
    }))
  )
  // const [division,setDivision] = useState('click')
  const { Option } = Select
  const [years, setYears] = useState([currentYear, preYear])
  // const [division, setDivision] = useState(['FA', 'FBP', 'WFJ', 'CORPORATE'])
  const [months, setMonths] = useState(['all'])
  const [selectYears, setSelectYears] = useState([currentYear])
  const [clicksession, setClicksession] = useState('click')
  const [updatetime, setupdatetime] = useState('')
  const [channel, setchannel] = useState(['all'])
  const [channelOpt, setchannelOpt] = useState([])

  const productopt = [
    { value: 'PPC', label: 'PPC' },
    { value: 'Brandzone', label: 'Brandzone' },
  ]
  const [product, setProduct] = useState(['PPC', 'Brandzone'])

  const [media, setMedia] = useState(['all'])
  const [mediaOpt, setMediaOpt] = useState([])

  const [category, setCategory] = useState(['all'])
  const [categoryOpt, setCategoryOpt] = useState([])

  const getUpdateTime = () => {
    getSum1_2({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
      sorts: [{ field: 'maxyearandmonth', type: 'DESC' }],
    }).then((res) => {
      setupdatetime(res[0].maxyearandmonth)
    })
  }
  const handleChannelChange = (val) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) return setchannel(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setchannel(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setchannel(['all']) //由其他转为默认

    return setchannel(val)
  }

  const handleMonthChange = (val) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) return setMonths(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setMonths(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setMonths(['all']) //由其他转为默认

    return setMonths(val)
  }

  const handleMediaChange = (val) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) return setMedia(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setMedia(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setMedia(['all']) //由其他转为默认
    return setMedia(val)
  }
  const handleCategoryChange = (val) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) return setCategory(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setCategory(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setCategory(['all']) //由其他转为默认
    return setCategory(val)
  }

  const resetting = (val) => {
    setProduct(['PPC', 'Brandzone'])
    setYears([currentYear, preYear])
  }

  const getChannels = () => {
    getPerformanceSum2({
      groups: ['channel'],
    }).then((res) => {
      localStorage.setItem(
        'channel',
        JSON.stringify(
          res.map((i) => {
            return i.channel
          })
        )
      )
      setchannelOpt([
        {
          label: '全部',
          value: 'all',
        },
        ...res.map((i) => ({ label: i.channel, value: i.channel })),
      ])
    })
  }
  const getCategorys = () => {
    getPerformanceSum2({
      groups: ['category'],
    }).then((res) => {
      localStorage.setItem(
        'category',
        JSON.stringify(
          res.map((i) => {
            return i.channel
          })
        )
      )
      setchannelOpt([
        {
          label: '全部',
          value: 'all',
        },
        ...res.map((i) => ({ label: i.category, value: i.category })),
      ])
    })
  }
  const getMedias = () => {
    getPerformanceSum2({
      groups: ['media'],
    }).then((res) => {
      localStorage.setItem(
        'media',
        JSON.stringify(
          res.map((i) => {
            return i.media
          })
        )
      )
      setMediaOpt([
        {
          label: '全部',
          value: 'all',
        },
        ...res.map((i) => ({ label: i.media, value: i.media })),
      ])
    })
  }

  useEffect(() => {
    getUpdateTime()
    // getMedias()
    //getCategorys()
    getChannels()
  }, [])
  useEffect(() => {
    localStorage.setItem(
      'channel',
      JSON.stringify(
        channel.includes('all')
          ? channelOpt.map((i) => {
              return i.value
            })
          : channel
      )
    )
  }, [channel])

  const getTitleTips = () => {
    return (
      <div>
        FBP One.Com Performance Trend&nbsp;
        <Tooltip
          placement="bottom"
          title={() => {
            return (
              <div>
                <div>Paid Search：含Baidu，360，Sogou，Shenma4个渠道下的Brandzone，PPC数据。</div>
                <div>CRM2.0： FBP公众号。</div>
                <div>Social ：Corp公众号，抖音品专，微博等渠道。</div>
                <div>
                  Other：Direct，OrganicSearch，Referral，CHANELApps，SocialPaid，Email等渠道(排除Display)。
                </div>
              </div>
            )
          }}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    )
  }
  return (
    <div className={wrap}>
      <div className="tool">
        <div className="toolBar">
          <div className="toolLine">
            <CombineDate onChange={(years) => setYears(years)} val={years} />
          </div>

          {/* <div className="toolLine">
          <div className="label">Media</div>
          <Select
            maxTagCount={1}
            options={mediaOpt}
            value={media}
            onChange={handleMediaChange}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div> */}
          {/* <div className="toolLine">
          <div className="label">Category</div>
          <Select
            maxTagCount={1}
            options={categoryOpt}
            value={category}
            onChange={handleCategoryChange}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div> */}
          <div className="toolLine">
            <div className="label">Product Line</div>
            <Select
              options={productopt}
              value={product}
              onChange={setProduct}
              style={{ width: '240px', marginRight: '12px' }}
              mode={'multiple'}
            />
          </div>
        </div>
        <div className="bottomTool">
          <Button onClick={resetting}>重置</Button>{' '}
          <span className="time"> Data update to {updatetime}</span>{' '}
        </div>
      </div>
      <Card style={{ marginTop: '-48px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <TrendPerformanceMonth
              title="Click Trend"
              field="click"
              product={product}
              unit={unit}
              division={division}
              year={years}
              style={{}}
            ></TrendPerformanceMonth>
          </div>
          <div style={{ flex: 1, marginLeft: '16px' }}>
            <TrendPerformanceMonth
              title="Session Trend"
              field="session"
              division={division}
              product={product}
              unit={unit}
              year={years}
              style={{}}
            ></TrendPerformanceMonth>
          </div>
        </div>

        {/* <div className={title}>Order & Revenue Performance trend</div> */}
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <TrendPerformanceMonth
              title="Order Trend"
              field="order"
              division={division}
              product={product}
              year={years}
              unit={unit}
              style={{}}
            ></TrendPerformanceMonth>
          </div>
          <div style={{ flex: 1, marginLeft: '16px' }}>
            <TrendPerformanceMonth
              title="Revenue Trend"
              field="revenue"
              unit={unit}
              division={division}
              product={product}
              year={years}
              style={{}}
            ></TrendPerformanceMonth>
          </div>
        </div>
        <div style={{ marginTop: '12px', display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <TrendLineProd
              formatpercent={true}
              product={product}
              title="CTR"
              field="ctr"
              division={division}
              years={years}
              style={{}}
            ></TrendLineProd>
          </div>
          <div style={{ flex: 1, marginLeft: '16px' }}>
            <TrendLineProd
              product={product}
              title="CPC"
              field="cpc"
              division={division}
              years={years}
              style={{}}
            ></TrendLineProd>
          </div>
        </div>
        <div style={{ marginTop: '12px', display: 'flex' }}>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 1, marginLeft: '16px' }}></div>
        </div>
      </Card>
      <Card
        title={getTitleTips()}
        headerLine={true}
        headerFilter={
          <>
            Year:
            <Select
              showArrow
              getPopupContainer={(n) => n.parentNode}
              onChange={(years) => setSelectYears(years.sort((a, b) => b - a))}
              defaultValue={selectYears}
              mode="multiple"
              options={yearsOpt}
              style={{ minWidth: '120px', marginLeft: '8px', marginRight: '8px' }}
            />
            Month:
            <Select
              getPopupContainer={(n) => n.parentNode}
              allowClear
              style={{ marginLeft: '8px', minWidth: '120px', marginRight: '8px' }}
              maxTagCount={1}
              showArrow
              value={months}
              onChange={handleMonthChange}
              defaultValue={months}
              mode="multiple"
              options={monthopt}
            />
          </>
        }
        style={{ marginTop: '16px' }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <TrendPerformanceMonth2
              clickjump={`/performance/searchShare`}
              title=".COM Session Trend & Paid Search Share"
              year={selectYears}
              unit={unit}
              month={months}
              division={division}
              style={{}}
              type="session"
            ></TrendPerformanceMonth2>
          </div>
          <div style={{ flex: 1, marginLeft: '16px' }}>
            <TrendPerformanceMonth2
              clickjump={`/performance/searchShare`}
              title=".COM Revenue Trend & Paid Search Share"
              year={selectYears}
              unit={unit}
              month={months}
              division={division}
              style={{}}
              type="revenue"
            ></TrendPerformanceMonth2>
          </div>
        </div>
        <div>
          <div style={{ marginTop: '12px', width: '100%', textAlign: 'right' }}>
            {' '}
            Channel:
            <Select
              getPopupContainer={(n) => n.parentNode}
              allowClear
              style={{ marginLeft: '8px', minWidth: '220px', textAlign: 'left' }}
              maxTagCount={3}
              showArrow
              value={channel}
              onChange={handleChannelChange}
              defaultValue={channel}
              mode="multiple"
              options={channelOpt}
            />
          </div>
          <TrendLineProd2
            formatpercent={true}
            product={product}
            title="CVR"
            field="cvr"
            channel={channel}
            channelOpt={channelOpt}
            month={months}
            years={selectYears}
            style={{}}
          ></TrendLineProd2>
        </div>
      </Card>
    </div>
  )
}

export default connect((state) => ({
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
  division: state.division,
  unit: state.unit,
}))(Performance)
