import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Empty, Radio } from 'antd'
import { redfeedsum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { mediaFormatOption, legendOptions } from '@/common/vars'
import { getTrendBarChartsOption, formatMillion } from './util'
import { formatNum, getLegendSelected } from '@/common/util'
export default function TrendDivisionAndYearRed(props) {
  const {
    fields = 'cost',
    title,
    cag,
    subcag,
    treeopt,
    setShowEmpty,
    division,
    years,
    months,
    mediaformat,
    formatpercent = false,
    unit,
    dataViewHeaderFirstTitle,
  } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)

  const [legendselect, setlegendselect] = useState([])
  const [legendCount, setLegendCount] = useState(0)
  const { updateCharts } = useCharts(trend, { legendselectchanged: setlegendselect })
  const [lastres, setlastres] = useState(null)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  /**
   * catogeryname 区分的类别名  比如 fa fbp
   * valname 具体显示的值 比如budget
   */
  const formatBarData = (data, catogeryname, valname, formatpercent) => {
    const years = [...new Set(data.map((i) => i.year))] // [2021 ,2022]
    years.sort((a, b) => a - b)
    const xData = [...new Set(data.map((i) => i.month))] // [1 ,2]
    const ynames = [...new Set(data.map((i) => i[catogeryname]))] // [fa,fbp]
    const yData = [] //[[fa-21-budget,fa-22-budget,name:fa],[fbp-21-budget,fbp-22-budget,name:fbp]]
    ynames.forEach((name) => {
      const res = []
      years.forEach((year) => {
        xData.forEach((month) => {
          let item = data.filter(
            (i) => i.month === month && i[catogeryname] === name && i.year === year
          )[0]
          res.push(item ? formatNum(item[valname], unit, 0) : 0)
        })
      })
      yData.push(res)
    })
    // })
    const xData1 = []
    years.forEach((y) => {
      xData1.push(...xData.map((i) => `${i}-${y.toString().slice(2)}`))
    })

    if (formatpercent) {
      const sum = []
      const len = yData[0].length
      for (let i = 0; i < len; i++) {
        sum[i] = yData.reduce((pre, cur) => {
          pre = pre + cur[i]
          return pre
        }, 0)
      }

      yData.forEach((i) => {
        sum.forEach((s, index) => {
          i[index] = ((i[index] / s) * 100).toFixed(2)
        })
      })
    }

    // debugger
    return {
      xData: xData1,
      yData,
      ynames,
      formatpercent,
      dataViewHeaderFirstTitle,
    }
  }

  const init = () => {
    if (JSON.stringify(treeopt) === '{}') return
    // debugger
    redfeedsum({
      fields: ['cost'],
      groups: ['month', 'productline', 'year'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: subcag.includes('all') ? treeopt['productline'] : subcag,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: cag.includes('all') ? treeopt['categorycorp'] : cag,
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: mediaformat.includes('all')
            ? mediaFormatOption.map((it) => {
                return it.value
              })
            : mediaformat,
        },
        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Red'],
        },
        // {
        //   field: 'mediaformat',
        //   operator: 'IN',
        //   values: ['RTB Feeds', 'GD Feeds'],
        // },
      ],
      sorts: [
        {
          field: 'month',
          type: 'ASC',
        },
        // {
        //   field: 'division',
        //   type: 'ASC',
        // },
      ],
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }

      setlastres(res)
      // debugger
      const formatdata = formatBarData(res, 'productline', fields, formatpercent)
      let data = getTrendBarChartsOption(formatdata)
      setLegendCount(data.series.length)
      let selected = getLegendSelected(
        data.series.map((i) => {
          return i.name
        }),
        checkAllVal
      )
      data.legend.selected = selected

      updateCharts(data)
    })
  }
  useEffect(() => {
    init()
  }, [years, months, cag, subcag, treeopt, mediaformat])

  useEffect(() => {
    if (!lastres) return //没有res的时候应该要等一个res再执行,将res放入依赖中
    // console.log('2.2 第二个图 unit changge rerender',unit)
    const data = getTrendBarChartsOption(
      formatBarData(lastres, 'productline', fields, formatpercent)
    )
    setLegendCount(data.series.length)
    let selected = getLegendSelected(
      data.series.map((i) => {
        return i.name
      }),
      checkAllVal
    )
    data.legend.selected = selected
    updateCharts(data)
  }, [unit, lastres])

  const [checkAllVal, setcheckAllVal] = useState(1)
  const onChange = ({ target: { value } }) => {
    setcheckAllVal(value)
  }

  useEffect(() => {
    if (checkAllVal < 2) init()
  }, [checkAllVal])

  const [first, setFirst] = useState(true)
  useEffect(() => {
    if (!first) {
      if (legendselect.length === 0) {
        setcheckAllVal(0)
      } else if (legendselect.length != legendCount) {
        setcheckAllVal(2)
      } else {
        setcheckAllVal(1)
      }
    }
    setFirst(false)
  }, [legendselect])

  return (
    // <div className="trend-wrap">
    //   <div ref={trend} style={{ width: '100%', height: '320px' }}></div>
    // </div>
    <div className="trend-wrap">
      <div className="title">
        <div>{title}</div>
        <div style={{ marginRight: '24px' }}>
          <Radio.Group
            options={legendOptions}
            onChange={onChange}
            value={checkAllVal}
            optionType="button"
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          marginRight: '24px',
          width: '100%',
          height: '360px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
