import Card from '../../components/Card'
import { useState, useEffect } from 'react'
import TrendDivisionAndYear from './trendDivisionAndYear'
import { connect } from 'react-redux'
import styles from './index.module.less'
import { Select } from 'antd'
import CrossFilter from '@/components/CrossFilter'
import { getSum } from '@/api/home'

import TrendPie2 from './trend-pie2'

import LineArea from '@/view/cross/line-area'
import { formatNum, numberWithCommas } from '@/common/util'

import { themecolor_pie1, themecolor_pie11, divisionOption } from '@/common/vars'
import Crossbar4 from './Crossbar4'
import Crossbar3 from './Crossbar3'

import { setDivision } from '@/redux/actions'
const { wrap } = styles
const cardstyle = {
  // marginTop: '16px',
}
const Budget = (props) => {
  const { currentYear, unit, yearsopt } = props
  const [months, setmonths] = useState(['all'])
  const [get, setGet] = useState(1)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const resetting = (val) => {
    setmonths(['all'])
    setMedia(['all'])
    setMediaFormat(['all'])
    setProductLine(['all'])
    setMediaChannel(['all'])
    setmediaGroup(['all'])
    setcampaign(['all'])
    setcategorycorp(['all'])
    setDivision(['all'])
    setyears([currentYear])
    setOptList(true)
  }

  const [ho, setHo] = useState(1)
  const [mediaGroup, setmediaGroup] = useState(['all'])
  const [mediaGroupOpt, setmediaGroupOpt] = useState([])

  const [years, setyears] = useState([currentYear])
  const [updatetime, setupdatetime] = useState('')
  const [cost, setCost] = useState(0)
  const [lastcost, setLastCost] = useState(0)
  const [productLine, setProductLine] = useState(['all'])
  const [productLineOpt, setProductLineOpt] = useState([])

  const [legendProductLine, setLegendProductLine] = useState([])
  const [media, setMedia] = useState(['all'])
  const [mediaOpt, setMediaOpt] = useState([])

  const [mediaFormat, setMediaFormat] = useState(['all'])
  const [mediaFormatOpt, setMediaFormatOpt] = useState([])

  const [mediaChannel, setMediaChannel] = useState(['all'])
  const [mediaChannelOpt, setMediaChannelOpt] = useState([])

  const [categorycorp, setcategorycorp] = useState(['all'])
  const [categorycorpOpt, setcategorycorpOpt] = useState([])

  const [campaign, setcampaign] = useState(['all'])
  const [campaignOpt, setcampaignOpt] = useState([])

  const [division, setDivision] = useState(['all'])
  const [divisionOpt, setDivisionOpt] = useState([])

  const getUpdateTime = () => {
    getSum({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
      sorts: [{ field: 'maxyearandmonth', type: 'DESC' }],
    }).then((res) => {
      setupdatetime(res[0].maxyearandmonth)
    })
  }

  const getOptLine = (val, fn, reset) => {
    let filters = []

    if (!mediaChannel.includes('all') && val != 'mediachannel')
      filters.push({
        field: 'mediaChannel',
        operator: 'IN',
        values: mediaChannel,
      })

    if (!mediaFormat.includes('all') && val != 'mediaformat')
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaFormat,
      })
    if (!mediaGroup.includes('all') && val != 'mediagroup')
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })

    if (!media.includes('all') && val != 'media')
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })

    if (!productLine.includes('all') && val != 'productline')
      filters.push({
        field: 'productline',
        operator: 'IN',
        values: productLine,
      })

    if (!campaign.includes('all') && val != 'campaignname')
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaign,
      })

    if (!division.includes('all') && val != 'division')
      filters.push({
        field: 'division',
        operator: 'IN',
        values: division,
      })

    if (!categorycorp.includes('all') && val != 'categorycorp')
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: categorycorp,
      })

    getSum({
      groups: [val],
      filters: reset || !filters.length ? undefined : filters,
      sorts: [
        {
          field: val,
          type: 'ASC',
        },
      ],
    }).then((res) => {
      fn([
        {
          label: '全部',
          value: 'all',
        },
        ...res.map((i) => ({ label: i[val], value: i[val] })),
      ])
    })
  }
  const handleChange = (val, fun) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) {
      setHo(val)
      return fun(['all'])
    }
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) {
      setHo(val)
      return fun(val.slice(1))
    } //由默认转为其他
    if (index === val.length - 1 && val.length > 0) {
      setHo(val)
      return fun(['all'])
    } //由其他转为默认
    setHo(val)
    return fun(val)
  }
  useEffect(() => {
    getUpdateTime()
  }, [])

  useEffect(() => {
    setOptList()
  }, [ho])

  useEffect(() => {
    getOverviewCost()
    if (years.length === 1 && !years.includes('all') && yearsopt.includes(years[0] - 1)) {
      getOverviewCost2()
    }
  }, [ho, years, months])

  const setOptList = (reset) => {
    getOptLine('media', setMediaOpt, reset)
    getOptLine('mediaformat', setMediaFormatOpt, reset)
    getOptLine('productline', setProductLineOpt, reset)
    getOptLine('division', setDivisionOpt, reset)
    getOptLine('mediachannel', setMediaChannelOpt, reset)
    getOptLine('mediagroup', setmediaGroupOpt, reset)
    getOptLine('categorycorp', setcategorycorpOpt, reset)
    getOptLine('campaignname', setcampaignOpt, reset)
    setGet(get + 1)
  }
  const getOverviewCost = () => {
    let filters = [
      {
        field: 'year',
        operator: 'IN',
        values: years.includes('all') ? [yearsopt[0]] : [Math.max(...years)], //years,
      },
      {
        field: 'month',
        operator: 'IN',
        values: months.includes('all')
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          : months.map((i) => map.indexOf(i)),
      },
    ]

    if (!mediaChannel.includes('all'))
      filters.push({
        field: 'mediaChannel',
        operator: 'IN',
        values: mediaChannel,
      })

    if (!mediaFormat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaFormat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })

    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })

    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })

    if (!campaign.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaign,
      })

    if (!division.includes('all'))
      filters.push({
        field: 'division',
        operator: 'IN',
        values: division,
      })

    if (!categorycorp.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: categorycorp,
      })
    getSum({
      title: '获取budgetamount Cost',
      fields: ['budget'],
      filters,
    }).then((res) => {
      setCost(res[0]?.budgetamount)
    })
  }

  const getOverviewCost2 = () => {
    let filters = [
      {
        field: 'year',
        operator: 'IN',
        values: [years[0] - 1], //years,
      },
      {
        field: 'month',
        operator: 'IN',
        values: months.includes('all')
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          : months.map((i) => map.indexOf(i)),
      },
    ]

    if (!mediaChannel.includes('all'))
      filters.push({
        field: 'mediaChannel',
        operator: 'IN',
        values: mediaChannel,
      })

    if (!mediaFormat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaFormat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })

    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })

    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })

    if (!campaign.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaign,
      })

    if (!division.includes('all'))
      filters.push({
        field: 'division',
        operator: 'IN',
        values: division,
      })

    if (!categorycorp.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: categorycorp,
      })
    getSum({
      title: '获取last budgetamount Cost2',
      fields: ['budget'],
      filters,
    }).then((res) => {
      setLastCost(res[0]?.budgetamount)
    })
  }
  return (
    <div className={wrap}>
      <CrossFilter
        updatetime={updatetime}
        cost={cost}
        unit={unit}
        resetting={resetting}
        division={division}
        setDivision={setDivision}
        months={months}
        years={years}
        setmonths={setmonths}
        setyears={setyears}
        // setProductLine={setProductLine}
      >
        <div className="toolLine">
          <div className="label">Media channel</div>
          <Select
            maxTagCount={1}
            options={mediaChannelOpt}
            value={mediaChannel}
            onChange={(val) => {
              handleChange(val, setMediaChannel)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
        <div className="toolLine">
          <div className="label">Media Format</div>
          <Select
            maxTagCount={1}
            options={mediaFormatOpt}
            value={mediaFormat}
            onChange={(val) => {
              handleChange(val, setMediaFormat)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
        <div className="toolLine">
          <div className="label">Media group</div>
          <Select
            maxTagCount={1}
            options={mediaGroupOpt}
            value={mediaGroup}
            onChange={(v) => {
              handleChange(v, setmediaGroup)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
        <div className="toolLine">
          <div className="label">Media</div>

          <Select
            maxTagCount={1}
            options={mediaOpt}
            value={media}
            onChange={(val) => {
              handleChange(val, setMedia)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>

        <div className="toolLine">
          <div className="label">Product Line</div>
          <Select
            maxTagCount={1}
            options={productLineOpt}
            value={productLine}
            onChange={(val) => {
              handleChange(val, setProductLine)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>

        <div className="toolLine">
          <div className="label">Campaign</div>
          <Select
            maxTagCount={1}
            options={campaignOpt}
            value={campaign}
            onChange={(v) => {
              handleChange(v, setcampaign)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
        <div className="toolLine">
          <div className="label">Division</div>
          <Select
            maxTagCount={1}
            options={divisionOpt}
            value={division}
            onChange={(v) => {
              handleChange(v, setDivision)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
        <div className="toolLine">
          <div className="label">Category</div>
          <Select
            maxTagCount={1}
            options={categorycorpOpt}
            value={categorycorp}
            onChange={(v) => {
              handleChange(v, setcategorycorp)
            }}
            style={{ width: '240px' }}
            mode={'multiple'}
          />
        </div>
      </CrossFilter>

      <Card title="Total Budget" style={cardstyle} headerLine={true}>
        <div className="line" style={{ marginBottom: '22px' }}>
          <div className="line-1-left ">
            <TrendPie2
              title="Budget By Media "
              get={get}
              division={division}
              mediaGroup={mediaGroup}
              setmediaGroup={(val) => {
                handleChange(val, setmediaGroup)
              }}
              mediaFormat={mediaFormat}
              setMediaFormat={(val) => {
                handleChange(val, setMediaFormat)
              }}
              categorycorp={categorycorp}
              campaign={campaign}
              unit={unit}
              years={years}
              months={months}
              productLine={productLine}
              mediaChannel={mediaChannel}
              media={media}
              dataViewHeaderFirstTitle="Media Group"
              // argsfn={argscreator(pieconfig1)}
              color={themecolor_pie1}
              color1={themecolor_pie11}
            ></TrendPie2>
          </div>

          <div className="line-1-right">
            <Crossbar4
              dataViewHeaderFirstTitle="Media Group"
              title="Budget Amount By "
              type="categorycorp"
              get={get}
              division={division}
              setDivision={(val) => {
                handleChange(val, setDivision)
              }}
              categorycorpOpt={categorycorpOpt}
              categorycorp={categorycorp}
              setcategorycorp={(val) => {
                handleChange(val, setcategorycorp)
              }}
              campaign={campaign}
              campaignOpt={campaignOpt}
              years={years}
              unit={unit}
              months={months}
              yearsopt={yearsopt}
              productLine={productLine}
              productLineOpt={productLineOpt}
              mediaChannel={mediaChannel}
              mediaChannelOpt={mediaChannelOpt}
              media={media}
              mediaOpt={mediaOpt}
              mediaFormat={mediaFormat}
              mediaFormatOpt={mediaFormatOpt}
              mediaGroup={mediaGroup}
              mediaGroupOpt={mediaGroupOpt}
              style={{ width: '100%' }}
              color={['#0f4e77']}
              sort={[
                {
                  field: 'category',
                  type: 'ASC',
                },
              ]}
            ></Crossbar4>
          </div>
          <div className="line-1-right">
            {years.length > 0 ? (
              <div className="chartPan">
                <div className="chartPan-chart">
                  <LineArea
                    divHeight="180"
                    get={get}
                    productLine={productLine}
                    campaign={campaign}
                    mediaChannel={mediaChannel}
                    media={media}
                    division={division}
                    categorycorp={categorycorp}
                    mediaGroup={mediaGroup}
                    mediaFormat={mediaFormat}
                    years={years.includes('all') ? yearsopt : years}
                    title={
                      'Total Media Spends' +
                      (years.length === 1 && yearsopt.includes(years[0] - 1) ? ' (YOY%)' : '')
                    }
                  ></LineArea>
                  <div className="areanum">
                    {numberWithCommas(formatNum(cost, unit, 0))}
                    {years.length === 1 && yearsopt.includes(years[0] - 1)
                      ? lastcost > 0 && cost > 0
                        ? ' (' + (((cost - lastcost) * 100) / lastcost).toFixed(2) + '%)'
                        : ''
                      : null}
                  </div>
                </div>

                {years.length === 1 && !years.includes('all') && yearsopt.includes(years[0] - 1) ? (
                  <div className="chartPan-chart">
                    <LineArea
                      divHeight="180"
                      years={years}
                      productLine={productLine}
                      productLineOpt={productLineOpt}
                      campaign={campaign}
                      campaignOpt={campaignOpt}
                      mediaChannel={mediaChannel}
                      mediaChannelOpt={mediaChannelOpt}
                      media={media}
                      mediaOpt={mediaOpt}
                      divisionOption={divisionOption}
                      division={division}
                      categorycorp={categorycorp}
                      categorycorpOpt={categorycorpOpt}
                      mediaGroup={mediaGroup}
                      mediaGroupOpt={mediaGroupOpt}
                      mediaFormatOpt={mediaFormatOpt}
                      mediaFormat={mediaFormat}
                      title="Total  Spends Last Year"
                      last="true"
                    ></LineArea>
                    <div className="areanum">{numberWithCommas(formatNum(lastcost, unit, 0))}</div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </Card>
      <Card title="" style={cardstyle} headerLine={true}>
        <div className="compaign-content">
          <div className="compaign-left">
            <Crossbar3 //换接口直接用3
              // reverse={true}
              title="Budget By "
              mediaGroup={mediaGroup}
              get={get}
              unit={unit}
              dataViewHeaderFirstTitle="Media Group"
              division={division}
              yearsopt={yearsopt}
              years={years}
              months={months}
              productLine={productLine}
              setProductLine={(val) => {
                handleChange(val, setProductLine)
              }}
              categorycorp={categorycorp}
              legendProductLine={legendProductLine}
              mediaChannel={mediaChannel}
              media={media}
              mediaFormat={mediaFormat}
              campaign={campaign}
              setcampaign={(val) => {
                handleChange(val, setcampaign)
              }}
              style={{ width: '100%' }}
              color={['#0f4e77']}
            ></Crossbar3>
          </div>
          <div className="compaign-right">
            <TrendDivisionAndYear
              get={get}
              title="Budget By Month & Status"
              dataViewHeaderFirstTitle="Media Group"
              division={division}
              years={years}
              campaign={campaign}
              months={months}
              yearsopt={yearsopt}
              unit={unit}
              productLine={productLine}
              mediaChannel={mediaChannel}
              media={media}
              mediaFormat={mediaFormat}
              categorycorp={categorycorp}
              mediaGroup={mediaGroup}
              setmonths={setmonths}
              setyears={setyears}
            ></TrendDivisionAndYear>
          </div>
        </div>
      </Card>
    </div>
  )
}
export default connect((state) => ({
  yearsopt: state.years.slice().reverse(),
  currentYear: state.years[state.years.length - 1],
  // division: state.division,
  unit: state.unit,
  // preYear: state.years[state.years.length - 2],
}))(Budget)
