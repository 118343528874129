import * as echarts from 'echarts'
import React, { useEffect, useRef, useState } from 'react'
import SeSelector from './seSelector'
import { Radio } from 'antd'
import { getSum1_2 } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendPieChartsOption2 } from './util'
import { getLegendSelected } from '@/common/util'
import { legendOptions, divisionOption } from '@/common/vars'
import { Empty } from 'antd'
export default function TrendPie(props) {
  const {
    fn = getSum1_2,
    title,
    division,
    years,
    months,
    productLine,
    category,
    campaignname,
    unit,
    yearsopt,
    setMediagroup,
    mediagroup,
    media,
    mediaformat,
    asset,
    get,
  } = props

  let checkGrouop = undefined

  const group = 'mediagroup'
  const trend = useRef(null)
  const [legendselect, setlegendselect] = useState([])
  const [legendCount, setLegendCount] = useState(0)
  const changePieName = (val) => {
    checkGrouop = val === checkGrouop ? undefined : val

    setMediagroup(checkGrouop ? [checkGrouop] : [])
  }
  const { updateCharts } = useCharts(trend, {
    legendselectchanged: setlegendselect,
    changePieName: changePieName,
  })
  const [empty, setEmpty] = useState(false)

  const [checkAllVal, setcheckAllVal] = useState(1)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const init = () => {
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Search'],
      },
      {
        field: group,
        operator: 'NOT_IN',
        values: ['', ' ', '-'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!mediagroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediagroup,
      })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })

    fn({
      //division
      fields: ['cost', 'mediagroup'],
      title: '饼',
      groups: [group],
      filters,
      // sorts: [
      //   {
      //     field: 'year',
      //     type: 'ASC',
      //   },
      // ],
    }).then((res) => {
      // debugger
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }

      for (let key in res[0]) {
        if (group.includes(key)) {
          let data = getTrendPieChartsOption2(
            res,
            {
              xname: key,
              years: years.includes('all') ? yearsopt : years,
            },
            unit
          )
          setLegendCount(data.series[0].data.length)
          let selected = getLegendSelected(
            data.series[0].data.map((i) => {
              return i.name
            }),
            checkAllVal
          )

          data.legend.selected = selected

          updateCharts(data)
          // }, 1000);
        }
      }
    })
  }
  useEffect(() => {
    init()
  }, [years, months, get, unit, group])
  useEffect(() => {
    if (checkAllVal < 2) init()
  }, [checkAllVal])

  const [first, setFirst] = useState(true)
  useEffect(() => {
    if (!first) {
      if (legendselect.length === 0) {
        setcheckAllVal(0)
      } else if (legendselect.length != legendCount) {
        setcheckAllVal(2)
      } else {
        setcheckAllVal(1)
      }
    }
    setFirst(false)
  }, [legendselect])

  return (
    <div className="trend-wrap">
      <div className="title">{title + (group === 'mediaformat' ? 'Format' : 'Group')}</div>
      {/* <div className="tool">
        <div style={{ marginRight: '24px' }}>
          <Radio.Group
            options={legendOptions}
            onChange={onChange}
            size="small"
            value={checkAllVal}
            optionType="button"
          />
        </div>
      </div> */}
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          width: '100%',
          height: '280px',
          marginRight: '24px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
