import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { getTableBudgetSum } from '@/api/home'
import { Table } from 'antd'
import { addtablekey } from './util'
import { divisionOption } from '@/common/vars'
import { formatNum, formatTableNum } from '@/common/util'
export default function TreeTable(props) {
  const {
    months,
    division,
    years,
    productLine,
    mediaChannel,
    mediaChannelOpt,
    media,
    mediaOpt,
    unit,
    yearsopt,
  } = props
  const [data, setData] = useState([])
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const columns = [
    {
      title: 'Media Format',
      dataIndex: 'mediaformat',
      key: 'mediaformat',
    },
    {
      title: 'Division',
      dataIndex: 'divisioncorp',
      key: 'divisioncorp',
    },
    {
      title: 'Baidu',
      dataIndex: 'Baidu',
      key: 'Baidu',
      render(val) {
        return formatTableNum(formatNum(val, unit, 0))
      },
      align: 'right',
    },
    {
      title: 'Qihu360',
      dataIndex: 'Qihu360',
      key: 'Qihu360',
      render(val) {
        return formatTableNum(formatNum(val, unit, 0))
      },
      align: 'right',
    },
    {
      title: 'Shenma',
      dataIndex: 'Shenma',
      key: 'Shenma',
      render(val) {
        return formatTableNum(formatNum(val, unit, 0))
      },
      align: 'right',
    },
    {
      title: 'Sogou',
      dataIndex: 'Sogou',
      key: 'Sogou',
      render(val) {
        return formatTableNum(formatNum(val, unit, 0))
      },
      align: 'right',
    },
    {
      title: '总计',
      dataIndex: 'sum',
      key: 'sum',
      render(val) {
        return formatTableNum(formatNum(val, unit, 0))
      },
      align: 'right',
    },
  ]

  useEffect(() => {
    getTableBudgetSum({
      title: 'Paid Search Pivot',
      filters: [
        { field: 'year', operator: 'IN', values: years.includes('all') ? yearsopt : years },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        {
          field: 'divisioncorp',
          operator: 'IN',
          values: division.includes('all') ? divisionOption : division,
        },
        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Baidu', 'Non-Baidu'],
        },
        {
          field: 'mediachannel',
          operator: 'IN',
          values: ['Search'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['Brandzone', 'PPC'],
        },
      ],
      sorts: [
        {
          field: 'mediaformat',
          type: 'ASC',
        },
        {
          field: 'divisioncorp',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      // res.forEach((i,index)=>{
      //   i.key = index
      //   if(i.children){
      //     i.children.forEach((c,index1)=>{
      //       c.key = `${index}_${index1}`
      //     })
      //   }
      // })
      addtablekey(res)
      setData(res)
    })
    console.log(
      '筛选条件变更',
      months,
      division,
      years,
      productLine,
      mediaChannel,
      mediaChannelOpt,
      media,
      mediaOpt
    )
  }, [months, division, years, productLine, mediaChannel, mediaChannelOpt, media, mediaOpt])

  return (
    <div className="trend-wrap">
      <Table columns={columns} dataSource={data} defaultExpandedRowKeys={[]} pagination={false} />
    </div>
  )
}
