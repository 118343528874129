import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { formatNum } from '@/common/util'
import { getPerformanceSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getMonthTrendBarChartsOption } from './util'
import { Select } from 'antd'
import { formatmonth } from './util'
import { Empty } from 'antd'
import { divisionOption } from '@/common/vars'
export default function TrendPerformanceMonth(props) {
  const { year, title, division, type, field, product, unit } = props
  const [empty, setEmpty] = useState(false)
  const trend = useRef(null)
  const { updateCharts } = useCharts(trend)
  useEffect(() => {
    getPerformanceSum({
      fields: [field],
      title,
      groups: ['year', 'month'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: year,
        },
        {
          field: 'divisioncorp',
          operator: 'IN',
          values: division.includes('all') ? divisionOption : division,
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: product,
        },
        {
          field: 'mediachannel',
          operator: 'IN',
          values: ['Search'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['PPC', 'Brandzone'],
        },
      ],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
        {
          field: 'month',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      if (res) {
        const xAxisData = [...new Set(res.map((i) => i.month))]
        const series = res.reduce((pre, cur) => {
          let name = cur.year
          if (!pre[name]) pre[name] = []
          pre[name].push(formatNum(cur[field], unit, 0))
          return pre
        }, {})

        console.log(series)
        updateCharts(getMonthTrendBarChartsOption({ xAxisData, series }, unit))
      }
    })
  }, [year, product, unit, division]) //division,

  const style = {
    title: {
      height: '48px',
      lineHeight: '48px',
      fontSize: '14px',
      fontFamily: 'PingFangSC-Medium, PingFang SC',
      fontWeight: '500',
      color: '#262626',
    },
    filterWrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }
  return (
    <div className="trend-wrap">
      <div style={style.title}>{title}</div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          marginTop: '8px',
          width: 'calc(100%)',
          height: '320px',
          ...style,
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
