import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Empty } from 'antd'
import { getIndustrySum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { formatNum } from '@/common/util'
import { getTrendBarChartsOption_hor, barhorconfig } from './util'
import axios from 'axios'
const TrendBarHor = React.memo(function TrendBarHor(props) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const {
    title,
    years,
    brand,
    setClickbrand,
    diffRankMap,
    curRank,
    category,
    division,
    media,
    unit,
  } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend, { setClickbrand })
  const monthsToNow = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(
    (i) => i <= new Date().getMonth() + 1
  )

  useEffect(() => {
    if (!brand.length || !media.length || !division) return setEmpty(true)
    getIndustrySum(
      {
        title: '第一行第一图',
        fields: ['searchindex'],
        groups: ['year', 'brand'],
        filters: [
          {
            field: 'year',
            operator: 'IN',
            values: years,
          },
          {
            field: 'brand',
            operator: 'IN',
            values: brand,
          },
          {
            field: 'division',
            operator: 'IN',
            values: [division],
          },
          {
            field: 'category',
            operator: 'IN',
            values: category,
          },

          {
            field: 'media',
            operator: 'IN',
            values: media,
          },
          {
            field: 'tag',
            operator: 'IN',
            values: ['Brand Total'],
          },

          // {
          //   field: 'month',
          //   operator: 'IN',
          //   values: monthsToNow,
          // },
        ],
        sorts: [
          {
            field: 'year',
            type: 'ASC',
          },
          {
            field: 'searchindex',
            type: 'DESC',
          },
        ],
      },
      { cancelToken: source.token }
    ).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      setClickbrand(res[0]?.brand)
      res = res.reverse()
      const data21 = res
        .filter((i) => i.year === years[1])
        .map((i) => {
          i.searchindex = formatNum(i.searchindex, unit, 0)
          return i
        })
      // const data21name = data21.map(i=>i.brand)
      const data22 = res
        .filter((i) => i.year === years[0])
        .map((i) => {
          i.searchindex = formatNum(i.searchindex, unit, 0)
          return i
        })
      // debugger
      const option = getTrendBarChartsOption_hor(
        { sd1: data21, sd2: data22, preyear: years[1], curyear: years[0] },
        { diffRankMap, curRank }
      )

      updateCharts(option)
    })
    return () => {
      console.log('Operation canceled by the user')
      source.cancel('Operation canceled by the user')
    }
  }, [years, brand, diffRankMap, media, unit])

  return (
    <div className="trend-wrap">
      <div className="title">
        <div>{title}</div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px', width: '100%' }}></Empty> : null}
      <div
        ref={trend}
        style={{ display: empty ? 'none' : 'block', width: '100%', height: '400px' }}
      ></div>
    </div>
  )
})

export default TrendBarHor
