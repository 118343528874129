import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Empty } from 'antd'
import { getPerformanceSum } from '@/api/home'
import { Radio } from 'antd'
import { getSum1 } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getScattersOption } from './util'
import { divisionOption } from '@/common/vars'
import { contentToOption, formatNum, numberWithCommas } from '@/common/util'

export default function Scatters(props) {
  const {
    unit,
    division,
    years,
    months,
    get,
    category,
    productLine,
    campaignname,
    mediaformat,
    media,
    mediaGroup,
    title,
    setProductLine,
    setCampaignname,
    setAsset,
    asset,
  } = props
  const trend = useRef(null)
  const [group, setGroup] = useState('campaignname')

  const [fields, setfields] = useState(['click', 'ctr'])
  const [empty, setEmpty] = useState(false)

  let checkGrouop = undefined
  const changePieName = (val) => {
    let g = localStorage.getItem('scattersGroup')
    checkGrouop = val === checkGrouop ? undefined : val
    if (g === 'productline') setProductLine(checkGrouop ? [checkGrouop] : [])
    else if (g === 'campaignname') setCampaignname(checkGrouop ? [checkGrouop] : [])
    else if (g === 'assets') setAsset(checkGrouop ? [checkGrouop] : [])
  }

  const { updateCharts } = useCharts(trend, { changePieName })
  const [lastres, setlastres] = useState(null)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const radioOption = [
    {
      label: 'Campaign',
      value: 'campaignname',
    },

    {
      label: 'Product Line',
      value: 'productline',
    },
    {
      label: 'Asset',
      value: 'assets',
    },
  ]

  const getOptText = () => {
    return radioOption.filter((i) => {
      return i.value === group
    })[0].label
  }

  const getma = async () => {
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Search'],
      },
      {
        field: group,
        operator: 'NOT_IN',
        values: ['', ' ', '-'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })
    filters.push({
      field: group,
      operator: 'NOT_IN',
      values: ['-', '', ' '],
    })
    return getPerformanceSum({
      fields,
      title: '散点图' + fields.slice(','),
      //  groups: [group],
      filters,
    })
  }
  const onChange2 = ({ target: { value } }) => {
    localStorage.setItem('scattersGroup', value)
    setGroup(value)
  }
  useEffect(() => {
    if (mediaGroup.length == 1 && mediaGroup[0] === 'Tencent') {
      setfields(['roi', 'atc'])
    } else if (mediaGroup.length == 1 && mediaGroup[0] === 'Red') {
      setfields(['ctr', 'er_post'])
    } else setfields(['click', 'ctr'])
  }, [mediaGroup])
  useEffect(() => {
    localStorage.setItem('scattersGroup', group)
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Search'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productline',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })

    filters.push({
      field: group,
      operator: 'NOT_IN',
      values: ['-', '', ' '],
    })
    let data = {
      name: '散点图数据',
      fields: fields.concat(['cost']),
      groups: [group],
      filters,
    }
    getPerformanceSum(data).then(async (res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      let x = 0
      let y = 0
      let d = (await getma())[0]
      x = d[fields[0]]
      y = d[fields[1]]

      if (fields[0] == 'click') {
        x = x / res.length
        y = d[fields[1]]
      }

      var charts = getScattersOption(res, [x, y], unit, getOptText(), group, fields)

      var toolbox = {
        feature: {
          saveAsImage: { backgroundColor: '#fff' },
          dataView: {
            lang: ['数据视图', '关闭', '导出Excel'],
            optionToContent: (opt) => {
              var series = opt.series

              // 表格头  先name再data
              var table =
                '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
                `<td>${getOptText()}</td>` +
                `<td>${fields[0]}</td>` +
                `<td>${fields[1]}</td>` +
                `<td>Cost</td>` +
                '</tr>'
              // 表格题  先name再data
              const namearr = series[0].data

              for (var i = 0, l = namearr.length; i < l; i++) {
                table +=
                  '<tr>' +
                  `<td>${numberWithCommas(series[0].data[i][3])}</td>` +
                  `<td>${numberWithCommas(series[0].data[i][0])}${
                    fields[0] === 'ctr' ? '%' : ''
                  }</td>` +
                  `<td>${numberWithCommas(series[0].data[i][1])}%</td>` +
                  `<td>${numberWithCommas(series[0].data[i][2])}</td>` +
                  '</tr>'
              }
              table += '</tbody></table>'
              return table
            },
            contentToOption,
          },
        },
      }
      charts.toolbox = toolbox
      updateCharts(charts)
    })
  }, [years, months, get, fields, unit, group])

  return (
    <div className="trend-wrap" style={{ width: '100%' }}>
      <div className="title">
        {fields[0].toUpperCase() + '  ' + fields[1].toUpperCase()}
        {title}
      </div>
      <div className="tool">
        <div style={{ width: '100%' }}>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            value={group}
            size="small"
            optionType="button"
          />
        </div>
      </div>

      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          width: '100%',

          height: '460px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
