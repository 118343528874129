import { connect } from 'react-redux'
import { useEffect, useState, useImperativeHandle, useMemo } from 'react'
import { getIndustrySum, getIndustryProduct } from '@/api/home'
import CombineDate from '@/components/combineDate'
import { Divider, Checkbox, Select, Space, Button } from 'antd'
const Filters = (props) => {
  const { onChange, val, currentYear, yearsOpt, preYear, defaultOpt, getRank } = props
  const [brandopt, setBrandopt] = useState([])
  const [mediaopt, setMediaopt] = useState([])
  const [axisOpt, setAxisOpt] = useState([{ label: 'FA', value: 'FA' }])
  const divisionData = ['Corp', 'FA', 'FBP', 'WFJ']
  const axisData = {
    Corp: ['Corp'],
    FBP: ['FR', 'MU', 'SK'],
    WFJ: ['FJ', 'WAT'],
    FA: ['FA'],
  }
  const getmediaopt = (axis) => {
    onChange('brand', [])
    if (axis.length === 0) return
    return getIndustrySum({
      groups: ['media'],
      sorts: [
        {
          field: 'media',
          type: 'ASC',
        },
      ],
      filters: axis
        ? [
            {
              field: 'category',
              operator: 'IN',
              values: axis,
            },
          ]
        : undefined,
    }).then((res) => {
      setMediaopt(res.map((i) => ({ label: i.media, value: i.media })))
      setTimeout(() => {
        const newMedia = res.map((i) => i.media)
        // onChange('brand', newbrand)
        handleMediaChange(newMedia[0])
      })
    })
  }

  const getbrandopt = (axis) => {
    onChange('brand', [])
    if (axis.length === 0) return

    return getIndustrySum({
      groups: ['brand'],
      sorts: [
        {
          field: 'brand',
          type: 'ASC',
        },
      ],
      filters: axis
        ? [
            {
              field: 'category',
              operator: 'IN',
              values: axis,
            },
          ]
        : undefined,
    }).then((res) => {
      setBrandopt(res.map((i) => ({ label: i.brand, value: i.brand })))
      setTimeout(() => {
        const newbrand = res.map((i) => i.brand)
        // onChange('brand', newbrand)
        handleBrandChange(newbrand)
      })
    })
  }
  const selectallbrand = (e) => {
    if (e.target.checked) {
      onChange(
        'brand',
        brandopt.map((i) => i.value)
      )
    } else {
      onChange('brand', [])
    }
  }
  // 初始化 brand列表
  useEffect(() => {
    getbrandopt([axisData[divisionData[1]][0]])
    getmediaopt([axisData[divisionData[1]][0]])
  }, [])

  const getAxisOpt = (division) => {
    //异步全选,带出brand
    onChange('category', [])
    setTimeout(() => {
      // onChange('category', axisData[division])
      handleCategoryChange(axisData[division])
    })
    return axisData[division].map((i) => {
      return {
        value: i,
        label: i,
      }
    })
  }

  const handleBrandChange = (brand) => {
    onChange('brand', brand) //通知父组件更改brand
  }
  const handleMediaChange = (media) => {
    onChange('media', [media]) //通知父组件更改brand
    //  setMediaopt(getmediaopt(val.category))
  }

  const handleDivisionChange = (division) => {
    onChange('division', division) //通知父组件更改division
    setAxisOpt(getAxisOpt(division))
    // onChange('category', [])
    // onChange('brand', [])
  }
  const handleCategoryChange = async (category) => {
    // if (getRank) {
    //   await getRank([val.division], category, val.years)
    // }

    onChange('category', category) //通知父组件更改category
    getbrandopt(category)
    // onChange('brand', [])
  }
  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',

          padding: '24px 24px 36px 24px',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'left' }}>
          <CombineDate
            onChange={(years) => {
              onChange('years', years)
              if (getRank) {
                getRank(val.division, val.category, years)
              }
            }}
            val={val.years}
          />

          <div className="searchItem">
            <div className="spantitle">Media:</div>
            <Select
              getPopupContainer={(n) => n.parentNode}
              allowClear
              style={{ marginLeft: '8px', minWidth: '240px' }}
              showArrow
              maxTagCount={1}
              options={mediaopt}
              value={val.media}
              onChange={handleMediaChange}
            ></Select>
          </div>
          <div className="searchItem">
            <span className="spantitle">Division:</span>

            <Select
              getPopupContainer={(n) => n.parentNode}
              style={{ width: '240px', marginLeft: '8px' }}
              options={divisionData.map((i) => ({ label: i, value: i }))}
              value={val.division}
              onChange={handleDivisionChange}
            ></Select>
          </div>

          <div className="searchItem">
            <div className="spantitle">Category:</div>
            <Select
              getPopupContainer={(n) => n.parentNode}
              allowClear
              style={{ marginLeft: '8px', minWidth: '240px' }}
              showArrow
              maxTagCount={1}
              options={axisOpt}
              mode="multiple"
              value={val.category}
              onChange={handleCategoryChange}
            ></Select>
          </div>
          <div className="searchItem">
            <div className="spantitle">Brand:</div>
            <Select
              getPopupContainer={(n) => n.parentNode}
              allowClear
              showArrow
              maxTagCount={0}
              options={brandopt}
              mode="multiple"
              value={val.brand}
              style={{ minWidth: '240px', marginLeft: '8px' }}
              onChange={handleBrandChange}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '2px 0',
                    }}
                  />
                  <Checkbox
                    checked={val.brand.length === brandopt.length}
                    onChange={selectallbrand}
                    style={{ marginLeft: '12px' }}
                  >
                    全选
                  </Checkbox>
                </>
              )}
            ></Select>
          </div>
        </div>
      </div>
    </>
  )
}
export default connect((state) => ({
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
}))(Filters)
