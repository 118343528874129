import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Radio, Select } from 'antd'
import { getPerformanceSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getMonthTrendBarChartsOption } from './util'
import { formatNum } from '@/common/util'

import { Empty } from 'antd'
import { divisionOption } from '@/common/vars'
export default function Crossbar3(props) {
  const {
    division,
    years,
    color,
    months,
    unit,
    title,
    category,
    get,
    productLine,
    campaignname,
    mediaGroup,
    media,
    mediaformat,
    setProductLine,
    setCampaignname,
    yearsopt,
    asset,
  } = props
  const [group, setGroup] = useState('campaignname')
  const radioOption = [
    {
      label: 'Campaign',
      value: 'campaignname',
    },
    {
      label: 'Product',
      value: 'productline',
    },
  ]
  const onChange2 = ({ target: { value } }) => {
    localStorage.setItem('detailGroup', value)
    setGroup(value)
  }
  let checkGrouop = undefined
  const changePieName = (val) => {
    let g = localStorage.getItem('detailGroup')
    checkGrouop = val === checkGrouop ? undefined : val
    if (g === 'productline') setProductLine(checkGrouop ? [checkGrouop] : [])
    else setCampaignname(checkGrouop ? [checkGrouop] : [])
  }
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend, { changePieName })
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  useEffect(() => {
    localStorage.setItem('detailGroup', group)

    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Search'],
      },
      {
        field: group,
        operator: 'NOT_IN',
        values: ['', ' ', '-'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })
    getPerformanceSum({
      title: title + group,
      group,
      fields: ['cost'],
      groups: [group],
      filters,
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }

      let data = res.map((i) => {
        return { name: i[group], value: formatNum(i.cost, unit, 0) }
      })

      updateCharts(
        getMonthTrendBarChartsOption(
          {
            data,
            color,
            dataViewHeaderFirstTitle: group,
          },
          unit
        )
      )
    })
  }, [years, months, division, unit, get, group])

  return (
    <div className="trend-wrap">
      <div className="title">{title + (group === 'productline' ? '  Product' : 'Campaign')}</div>
      <div className="tool">
        <div style={{ width: '170px' }}>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            value={group}
            size="small"
            optionType="button"
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          width: 'calc(100%)',
          height: '280px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
