const color = {
  '@primary-color': '#333333',
  '@layout-body-background': '#f2f3f5',
  '@menu-item-active-bg': '#F2F3F5',
  '@select-item-selected-bg': '#F2F3F5',
}
const themecolor = [
  'rgb(0,0,0)',
  'rgb(127,127,127)',
  'rgb(175,175,175)',
  'rgb(208,208,208)',
  'rgb(222,176,126)',
]
const themecolor1 = [
  'rgb(0,0,0,0.6)',
  'rgb(127,127,127,0.6)',
  'rgb(175,175,175,0.6)',
  'rgb(208,208,208,0.6)',
  'rgb(222,176,126,0.6)',
]
const themecolor_two = ['rgb(0,0,0)', 'rgb(208,208,208)']
const themecolor_pie1 = ['#0f4e77', '#af1a12', '#907b6e']
const themecolor_pie11 = ['rgba(15,78,119,0.6)', 'rgba(175,26,18,0.6)', 'rgba(144,123,110,0.6)']
const themecolor_pie2 = ['#87a3bf', '#ec8334', '#f4ca47']
const themecolor_pie21 = ['rgba(135,163,191,0.6)', 'rgba(236,131,52,0.6)', 'rgba(244,202,71,0.6)']
const themecolor_pie3 = ['#cf9050', '#ddaf81']
const themecolor_pie31 = ['rgba(207,144,80,0.6)', 'rgba(221,175,129,0.6)']
const themecolor_se = [
  'rgba(91,155,213,1)',
  'rgba(112,173,71,1)',
  'rgba(226,132,56,1)',
  'rgba(255,192,0,1)',
]
const themecolor_se1 = [
  'rgba(91,155,213,0.6)',
  'rgba(112,173,71,0.6)',
  'rgba(226,132,56,0.6)',
  'rgba(255,192,0,0.6)',
]
//eslint-disable-next-line
//mark=  1.2: crossPerformance;2.2: momentsRTBWechat ;2.3: channelFeedsWechat;2.4  GDFeedsRed	;
//维度type=1 指标type=2
const dimensionOption = [
  {
    label: 'Media',
    value: 'media',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
    },
  },
  {
    label: 'Media Format',
    value: 'mediaformat',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
    },
  },
  {
    label: 'Division',
    value: 'divisioncorp',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
      momentsRTBWechat: {
        option: true, //可选项
        default: true, //默认选项
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Category',
    value: 'categorycorp',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
      momentsRTBWechat: {
        option: true,
        default: true,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Date',
    value: 'dateline',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: false, //默认选项
      },
      momentsRTBWechat: {
        option: true, //可选项
        default: false, //默认选项
      },
    },
  },
  {
    label: 'Month',
    value: 'month',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: false, //默认选项
      },
      momentsRTBWechat: {
        option: true, //可选项
        default: false, //默认选项
      },
    },
  },
  {
    label: 'Year',
    value: 'year',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: false, //默认选项
      },
      momentsRTBWechat: {
        option: true, //可选项
        default: false, //默认选项
      },
    },
  },
  {
    label: 'Campaign Name',
    value: 'campaignname',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: false, //默认选项
      },
      momentsRTBWechat: {
        option: true,
        default: true,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Product Line',
    value: 'productline',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: false, //默认选项
      },
      momentsRTBWechat: {
        option: true,
        default: true,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Assets',
    value: 'assets',
    type: 1,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: false, //默认选项
      },
      momentsRTBWechat: {
        option: true,
        default: false,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
      GDFeedsRed: {
        option: true,
        default: false,
      },
    },
  },
  // {
  //   label: 'Category',
  //   value: 'category',
  //   type: 1,
  //   mark: {
  //     momentsRTBWechat: {
  //       option: true,
  //       default: true,
  //     },
  //     channelFeedsWechat: {
  //       option: true,
  //       default: true,
  //     },
  //   },
  // },
  {
    label: 'TA(Core/Re/Po)',
    value: 'ta2',
    type: 1,
    mark: {
      momentsRTBWechat: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'TA(Target 1)',
    value: 'target1',
    type: 1,
    mark: {
      momentsRTBWechat: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Start Date',
    value: 'startdate',
    type: 1,
    mark: {
      momentsRTBWechat: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'End Date',
    value: 'enddate',
    type: 1,
    mark: {
      momentsRTBWechat: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'Period',
    value: 'period',
    type: 1,
    mark: {
      momentsRTBWechat: {
        option: true,
        default: false,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Asset type',
    value: 'assettype',
    type: 1,
    mark: {
      channelFeedsWechat: {
        option: true,
        default: false,
      },
      GDFeedsRed: {
        option: true,
        default: false,
      },
    },
  },
  // {
  //   label: 'Product Line(SubCategory)',
  //   value: 'subcategory',
  //   type: 1,
  //   mark: {
  //     GDFeedsRed: {
  //       option: true,
  //       default: true,
  //     },
  //   },
  // },
  {
    label: 'Placement',
    value: 'placement',
    type: 1,
    mark: {
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Person/Product',
    value: 'content',
    type: 1,
    mark: {
      GDFeedsRed: {
        option: true,
        default: false,
      },
    },
  },
]

const indexOption = [
  {
    label: 'Impression',
    value: 'impression',
    type: 2,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
      momentsRTBWechat: {
        option: true,
        default: true,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Click',
    value: 'click',
    tips: 'Wechat渠道定义：Click to Landing |其他渠道定义：Click',
    type: 2,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
      momentsRTBWechat: {
        option: true,
        default: false,
      },
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Traffic',
    value: 'traffic',
    tips: 'Moments RTB定义：有数Traffic|Channel Feeds定义：Click to Landing|其他渠道：GA Session',
    type: 2,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
      channelFeedsWechat: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'Order',
    value: 'order',
    type: 2,
    tips: 'Wechat渠道定义：ADQ Order|其他渠道：GA Order',
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
      momentsRTBWechat: {
        option: true,
        default: true,
      },
      channelFeedsWechat: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'Revenue',
    value: 'revenue',
    tips: 'Wechat渠道定义：ADQ Order Revenue|其他渠道：GA Revenue',
    type: 2,
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
      momentsRTBWechat: {
        option: true,
        default: true,
      },
      channelFeedsWechat: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'CTR',
    value: 'ctr',
    type: 2,
    tips: 'Wechat渠道：Click to Landing / Impression |Red渠道：Click / Impression',
    mark: {
      crossPerformance: {
        option: true, //crossPerformance下的可选项
        default: true, //默认选项
      },
      momentsRTBWechat: {
        option: true,
        default: true,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  // {
  //   label: 'Budget Amount',
  //   value: 'budget',
  //   type: 2,
  //   mark: {
  //     crossPerformance: {
  //       option: true,
  //       default: false,
  //     },
  //   },
  // },
  {
    label: 'Engagement-WeChat',
    value: 'engagementwechat',
    type: 2,
    tips: 'Wechat渠道互动次数|Moments RTB定义：Like+Comments+Follow+Click to Landing+Click on Profile Image+Click on Nickname|Channel Feeds定义：|Click to Landing+Like+Comments+Follow+Share+Explore Account+Click Copy/Comment Section(点击展开评论区)',
    mark: {
      crossPerformance: {
        option: true,
        default: false,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'Engagement-Post',
    value: 'engagementpost',
    type: 2,
    tips: 'Red渠道互动次数,|公式：Like+Comments+Share+Favorite',
    mark: {
      crossPerformance: {
        option: true,
        default: false,
      },
      GDFeedsRed: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'ER-WeChat',
    value: 'er_wechat',
    type: 2,
    tips: 'Wechat渠道互动率，|公式：Engagement-Wechat / Impression',
    mark: {
      crossPerformance: {
        option: true,
        default: false,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'ER-Post',
    value: 'er_post',
    type: 2,
    tips: 'Red渠道互动率，|公式：Engagement-Post / Click',
    mark: {
      crossPerformance: {
        option: true,
        default: false,
      },
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'CPM',
    value: 'cpm',
    type: 2,
    tips: '千次展现成本，|公式：Cost / Impression *1000',
    mark: {
      crossPerformance: {
        option: true,
        default: false,
      },
      channelFeedsWechat: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'CPC',
    value: 'cpc',
    type: 2,
    tips: 'Cost / Click',
    mark: {
      crossPerformance: {
        option: true,
        default: false,
      },
      momentsRTBWechat: {
        option: true,
        default: false,
      },
      channelFeedsWechat: {
        option: true,
        default: false,
      },
      GDFeedsRed: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'CVR-Wechat',
    value: 'cvr_wechat',
    type: 2,
    tips: 'Wechat渠道转化率，|公式：Order / 加入购物车量',
    mark: {
      crossPerformance: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'CVR-Search',
    value: 'cvr_search',
    type: 2,
    tips: 'Paid Search渠道转化率，|公式：Order / Traffic',
    mark: {
      crossPerformance: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'Add to Cart',
    value: 'webaddtocart',
    type: 2,
    mark: {
      momentsRTBWechat: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: '加购率(ATC/CLK)',
    value: 'webaddtocart_click',
    type: 2,
    mark: {
      momentsRTBWechat: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'ROI',
    value: 'roi',
    type: 2,
    tips: 'Revenue / Cost',
    mark: {
      momentsRTBWechat: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'COST',
    value: 'cost',
    tips: '实际广告投放消耗',
    type: 2,
    mark: {
      crossPerformance: {
        option: true,
        default: true,
      },
      momentsRTBWechat: {
        option: true,
        default: false,
      },
      channelFeedsWechat: {
        option: true,
        default: true,
      },
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  {
    label: 'T+0 ROI',
    value: 't0ysrevenuema',
    type: 2,
    tips: '有数T+0 Revenue / Cost',
    mark: {
      momentsRTBWechat: {
        option: true,
        default: false,
      },
    },
  },

  {
    label: 'T+7 ROI',
    value: 't7ysrevenuema',
    type: 2,
    tips: '有数T+7 Revenue / Cost',
    mark: {
      momentsRTBWechat: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'T+15 ROI',
    value: 't15ysrevenuema',
    type: 2,
    tips: '有数T+15 Revenue / Cost',
    mark: {
      momentsRTBWechat: {
        option: true,
        default: false,
      },
    },
  },
  {
    label: 'Click to Landing',
    value: 'clicktolanding',
    type: 2,
    mark: {
      channelFeedsWechat: {
        option: true,
        default: true,
      },
    },
  },
  // {
  //   label: 'ECTR',
  //   value: 'ectr',
  //   type: 2,
  //   mark: {
  //     channelFeedsWechat: {
  //       option: true,
  //       default: true,
  //     },
  //   },
  // },
  {
    label: 'CPE-Post',
    value: 'cpe_post',
    type: 2,
    tips: 'Red渠道平均互动成本，|公式：Cost / Engagement-Post',
    mark: {
      GDFeedsRed: {
        option: true,
        default: true,
      },
    },
  },
  // {
  //   label: 'CPE-WeChat',
  //   value: 'cpe_wechat',
  //   type: 2,
  //   mark: {
  //     channelFeedsWechat: {
  //       option: true,
  //       default: false,
  //     },
  //   },
  // },
  // {
  //   label: 'ER',
  //   value: 'er',
  //   type: 2,
  //   mark: {
  //     GDFeedsRed: {
  //       option: true,
  //       default: true,
  //     },
  //   },
  // },
  {
    label: 'Collection',
    value: 'collection',
    type: 2,
    mark: {
      GDFeedsRed: {
        option: true,
        default: false,
      },
    },
  },
]

const sortOptions = [
  {
    label: 'budget升序',
    value: 'budgetamount|DESC',
  },
  {
    label: 'budget降序',
    value: 'budgetamount|ASC',
  },
  {
    label: 'campaign创建时间升序',
    value: 'minmonth|DESC',
  },
  {
    label: 'campaign创建时间降序',
    value: 'minmonth|ASC',
  },
  // {
  //   label: '按campaignname升序',
  //   value: 'campaignname|DESC',
  // },
  // {
  //   label: '按campaignname降序',
  //   value: 'campaignname|ASC',
  // },
]

const unitOptions = [
  {
    label: 'Mil',
    value: 'm',
  },
  {
    label: 'K',
    value: 'k',
  },
  {
    label: '无单位',
    value: 'n',
  },
]
const legendOptions = [
  {
    label: '全选',
    value: 1,
  },
  {
    label: '全不选',
    value: 0,
  },
]
const divisionOption = ['Corp', 'FBP', 'FA', 'WFJ']
const categoryOption = ['Corp', 'FR', 'SK', 'MU', 'BEAUTY PROGRAM', 'FA', 'WAT', 'FJ']
const mediaFormatOption = [
  { value: 'all', label: '全部' },
  { value: 'GD Feeds', label: 'GDFeeds' },
  { value: 'RTB Feeds', label: 'RTBFeeds' },
]
module.exports = {
  color,
  themecolor,
  themecolor1,
  themecolor_two,
  themecolor_pie1,
  themecolor_pie11,
  themecolor_pie2,
  themecolor_pie21,
  themecolor_pie3,
  themecolor_pie31,
  themecolor_se,
  themecolor_se1,
  dimensionOption,
  indexOption,
  unitOptions,
  divisionOption,
  categoryOption,
  mediaFormatOption,
  legendOptions,
  sortOptions,
}
