import { Layout, Dropdown, Menu, Radio, Select } from 'antd'

// import "./index.less"
import styles from './index.module.less'
import { useNavigate } from 'react-router-dom'
import { store } from '@/redux'
import { connect } from 'react-redux'
import { refresh } from '@/redux/actions'
import { UserOutlined } from '@ant-design/icons'
import logo from '@/assets/logo.png'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { setDivision, setUnit, setCollapsed } from '@/redux/actions'
import { unitOptions } from '@/common/vars'
import { useState } from 'react'
const { Header } = Layout
const { wrap } = styles

const layoutHeader = (props) => {
  const { collapsed, setCollapsed, division, setDivision, unit, setUnit } = props
  const url = window.location.href
  // const [division, setProductLine] = useState(['all'])

  const handlesetDivision = (val) => {
    // 对division增加互斥逻辑
    if (!val.length) return setDivision(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setDivision(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setDivision(['all']) //由其他转为默认
    return setDivision(val)
  }
  const onUnitChange = (val) => {
    setUnit(val.target.value)
  }
  const divisionopt = [
    { value: 'all', label: '全部' },
    { value: 'Corp', label: 'Corp' },
    { value: 'FBP', label: 'FBP' },
    { value: 'FA', label: 'FA' },
    { value: 'WFJ', label: 'WFJ' },
  ]

  const navigate = useNavigate() // eslint-disable-line
  const handleLoginOut = () => {
    props.refresh()
    localStorage.clear()
    navigate('/login')
  }
  const menu = (
    <Menu
      onClick={handleLoginOut}
      items={[
        {
          key: '1',
          label: <a onClick={(e) => e.preventDefault()}>退出登录</a>,
        },
      ]}
    />
  )
  const style = {
    marginLeft: '58px',
    display: 'flex',
    alignItems: 'center',
  }
  return (
    <div className={wrap}>
      <Header className="header">
        <div className="left">
          <img src={logo} alt="" style={{ width: '24px' }} />
          <div className="title">Dashboard</div>
          <div style={style}>
            {collapsed ? (
              <MenuUnfoldOutlined
                onClick={() => {
                  setCollapsed(false)
                }}
              />
            ) : (
              <MenuFoldOutlined
                onClick={() => {
                  setCollapsed(true)
                }}
              />
            )}
            {!url.includes('cross/budget') &&
              !url.includes('division/index') &&
              !url.includes('detail/cost') &&
              !url.includes('industry/index') &&
              !url.includes('cross/performance') && (
                <div style={{ marginLeft: '50px' }}>
                  Division：
                  {/* <Radio.Group value={division} onChange={(e) => setDivision(e.target.value)}>
                <Radio.Button value="all">全部</Radio.Button>
                <Radio.Button value="FA">FA</Radio.Button>
                <Radio.Button value="FBP">FBP</Radio.Button>
                <Radio.Button value="WFJ">WFJ</Radio.Button>
                <Radio.Button value="CORPORATE">Corp</Radio.Button>
              </Radio.Group> */}
                  <Select
                    maxTagCount={5}
                    options={divisionopt}
                    value={division}
                    onChange={handlesetDivision}
                    style={{ width: '270px' }}
                    mode={'multiple'}
                  />
                </div>
              )}

            <div style={{ marginLeft: '12px' }}>
              单位：
              <Radio.Group
                options={unitOptions}
                onChange={onUnitChange}
                className="type"
                defaultValue={unit}
                optionType="button"
              />
            </div>
          </div>
        </div>
        <div className="right">
          <UserOutlined></UserOutlined>
          <Dropdown overlay={menu}>
            <div className="user">{store.getState().auth.name || 'admin'}</div>
          </Dropdown>
        </div>
      </Header>
    </div>
  )
}
export default connect(
  (state) => ({
    division: state.division,
    unit: state.unit,
    collapsed: state.collapsed,
  }),
  { refresh, setDivision, setUnit, setCollapsed }
)(layoutHeader)
