import { Menu } from 'antd'
import { connect } from 'react-redux'
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  AppstoreOutlined,
  DashboardOutlined,
  DatabaseOutlined,
} from '@ant-design/icons'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { requestYears } from '@/redux/actions'
const LayoutMenu = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { collapsed, setCollapsed, roleId } = props
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    }
  }

  const items = [
    getItem('Cross Platform Overview', 'Cross', <AppstoreOutlined />, [
      getItem('Cross Budget', '/cross/budget'),
      getItem('Campaign Performance Detail', '/detail/cost'),
      getItem('Cross Performance Pivot', '/cross/performance'),

      getItem('Tradition Search', 'Tradition Search', <AppstoreOutlined />, [
        getItem('Paid Search FQ', '/home/index'),
        getItem('Paid Search Performance', '/performance/index'),
      ]),
    ]),

    getItem('Industry Index', 'Industry Index', <AppstoreOutlined />, [
      getItem('Overview index', '/industry/index'),
      getItem('Division index', '/division/index'),
    ]),

    // getItem('Cross Budget', '/cross/budget', <AppstoreOutlined />),
    // getItem('Cross Performance Pivot', '/cross/performance', <AppstoreOutlined />),

    //getItem('Paid Search FQ', '/home/index', <AppstoreOutlined />), //调整1.4
    //getItem('Paid Search Performance', '/performance/index', <AppstoreOutlined />), //调整后的1.4.2
    // getItem('Campaign Performance Detail', '/detail/cost', <AppstoreOutlined />), //新增1.3
    // getItem('Overview index', '/industry/index', <AppstoreOutlined />), //调整1.4
    // getItem('Division index', '/division/index', <AppstoreOutlined />), //调整1.4

    // getItem('Finance Budget', '/home/index', <AppstoreOutlined />),
    // getItem('Performance', '/performance/index', <DashboardOutlined />),
    // getItem('Industry', '/industry/index', <PieChartOutlined />),
    // getItem('Industry Index', 'Industry', <PieChartOutlined />, [
    //   getItem('Overview index', '/industry/index'),
    //   getItem('Division index', '/division/index'),
    // ]),
  ]
  if (roleId <= 3) {
    sessionStorage.setItem('channelPerm', 1)
    // >3 操作员  不显示上传页面
    // items.push(getItem('Upload', '/upload/index', <DatabaseOutlined />))
    items.push(
      getItem('Product', 'Product', <AppstoreOutlined />, [
        // getItem('Traditional-Search', 'TraditionalSearch', null, [
        //   getItem('Paid Search FQ', '/home/index'),
        //   getItem('Paid Search Performance', '/performance/index'),
        // ]),
        getItem('Moments RTB-Wechat', '/products/moment'), ///product/moment
        getItem('Channel Feeds-Wechat', '/products/Wechat'), ///product/moment
        getItem('Feeds- Red', '/products/red'), ///product/moment
      ])
    )
    items.push(getItem('Upload', '/upload/index', <DatabaseOutlined />))
  } else {
    sessionStorage.removeItem('channelPerm')
  }
  const handleSelct = async ({ key }) => {
    let apiname = 'budget' //不同模块年份调到接口不一样
    if (key.includes('cross')) {
      if (key.includes('performance')) {
        apiname = 'crossperformance'
      } else {
        apiname = 'crossbudget'
      }
    } else if (key.includes('/products/Wechat')) {
      apiname = 'wechatfeed'
    } else if (key.includes('/products/moment')) {
      apiname = 'wechatrtb'
    } else if (key.includes('red')) {
      apiname = 'redfeed'
    } else if (key.includes('division')) {
      apiname = 'industry'
    } else {
      apiname = 'newbudget'
    }
    await props.requestYears(apiname)
    navigate(key)
  }
  return (
    <>
      <Menu
        theme="light"
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        items={items}
        onClick={handleSelct}
      />
    </>
  )
}
export default connect((state) => ({ roleId: state.auth?.roleId }), { requestYears })(LayoutMenu)
