import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState, title } from 'react'

import { getSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getLineAreaOption } from './util'
import axios from 'axios'
import { formatNum } from '@/common/util'
import { Empty } from 'antd'
export default function TrendLine(props) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const {
    title,
    years,
    unit,
    divHeight,
    last,
    productLine,
    campaign,
    mediaChannel,
    media,
    division,
    categorycorp,
    mediaGroup,
    mediaFormat,
    get,
  } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  useEffect(() => {
    let filters = []
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: last ? [years[0] - 1] : years,
      })

    filters.push({
      field: 'month',
      operator: 'IN',
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    })

    if (!mediaChannel.includes('all'))
      filters.push({
        field: 'mediaChannel',
        operator: 'IN',
        values: mediaChannel,
      })

    if (!mediaFormat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaFormat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })

    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })

    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })

    if (!campaign.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaign,
      })

    if (!division.includes('all'))
      filters.push({
        field: 'division',
        operator: 'IN',
        values: division,
      })

    if (!categorycorp.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: categorycorp,
      })

    getSum({
      title: '右上角 Total Media Spends' + years[0] - 1,
      fields: ['budget'],
      groups: ['year', 'month'],
      filters,
      sorts: [
        {
          field: 'month',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      let xdata = []
      let seriesData = []
      let year2 = last
        ? [years[0] - 1]
        : years.sort(function (a, b) {
            return a - b
          })

      year2.forEach((y) => {
        months.forEach((m) => {
          let c = res.filter((r) => {
            return r.year == y && r.month == m
          })

          seriesData.push(c.length > 0 ? formatNum(c[0].budgetamount, unit, 2) : 0)
          xdata.push(y + '-' + m)
        })
      })

      updateCharts(getLineAreaOption({ xdata, seriesData, title }, unit))
    })
    return () => {
      source.cancel('Operation canceled by the user')
    }
  }, [years, unit, get])

  return (
    <div className="trend-wrap" style={{ textAlign: 'right' }}>
      <div className="title">
        <div></div>
      </div>

      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          display: empty ? 'none' : 'block',
          width: '100%',
          minHeight: '160px',
          height: divHeight + 'px',
        }}
      ></div>
    </div>
  )
}
