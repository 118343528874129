import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Empty } from 'antd'
import { getSum1 } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getScattersOption } from './util'

import { contentToOption, formatNum } from '@/common/util'
export default function Scatters(props) {
  const {
    mafield,
    fields = 'cost',
    ysval,
    title,
    setShowEmpty,
    division,
    years,
    months,
    axis,
    axisOpt,
    product,
    cag,
    cagopt,
    unit,
    valname,
    productOpt,
    dataViewHeaderFirstTitle,
    formatdatafn,
    formatpercent = false,
  } = props
  const trend = useRef(null)
  const [average, setAverage] = useState([])
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const [lastres, setlastres] = useState(null)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  /**
   * catogeryname 区分的类别名  比如 fa fbp
   * valname 具体显示的值 比如budget
   */

  const formatP = (val) => {
    return val
  }
  const formatBarData = (data, catogeryname, valname) => {
    // debugger
    const years = [...new Set(data.map((i) => i.year))]
    years.sort((a, b) => a - b)
    const months = [...new Set(data.map((i) => i.month))] // [1 ,2]
    const xData = [...new Set(data.map((i) => i[catogeryname]))] // [fa,fbp]
    const yData = []
    months.forEach((month) => {
      years.forEach((year) => {
        const res = []
        xData.forEach((name) => {
          let item = data.filter(
            (i) => i.month === month && i[catogeryname] === name && i.year === year
          )[0]
          res.push(item ? (formatdatafn ? item[valname] : formatNum(item[valname], unit, 0)) : 0)
        })
        yData.push(res)
      })
    })
    const ynames = []
    years.forEach((y) => {
      ynames.push(...months.map((i) => `${map[i]}-${y.toString().slice(2)}`))
    })

    // debugger
    return {
      xData, //[3,2]      【m1,m2,n3】
      yData, // [[2000,2500],[500,700],[[300,200]]] [[2000,500,300] [...],[],[]] //4个yname的xdata值
      ynames, //[n1 m2 m3]  [2-23,3-23，2-21，3-21]
      dataViewHeaderFirstTitle,
    }
  }
  const getma = async () => {
    return getSum1({
      fields: mafield,
      title: '散点图？',
      groups: ['year'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
        },
        {
          field: 'campaignname',
          operator: 'IN',
          values: cag.includes('all') ? cagopt.slice(1).map((i) => i.value) : cag,
        },
        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Tencent'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['Moments RTB'],
        },
      ],
    })
  }
  useEffect(() => {
    // if (!axisOpt.length) return
    // if (!productOpt.length) return

    let data = {
      name: '散点图',
      fields: ['roi', 'atc', 'cost'],
      groups: ['campaignname'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
        },
        {
          field: 'campaignname',
          operator: 'IN',
          values: cag.includes('all') ? cagopt.slice(1).map((i) => i.value) : cag,
        },
        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Tencent'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['Moments RTB'],
        },
      ],
    }
    getSum1(data).then(async (res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      const { roi: x, atc: y } = (await getma())[0]

      // debugger
      var charts = getScattersOption(res, [x, y], unit)
      var toolbox = {
        feature: {
          saveAsImage: { backgroundColor: '#fff' },
          dataView: {
            lang: ['数据视图', '关闭', '导出Excel'],
            optionToContent: (opt) => {
              var series = opt.series

              // 表格头  先name再data
              var table =
                '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
                `<td>Name</td>` +
                `<td>ROI</td>` +
                `<td>ATC</td>` +
                `<td>Cost</td>` +
                '</tr>'
              // 表格题  先name再data
              const namearr = series[0].data
              for (var i = 0, l = namearr.length; i < l; i++) {
                table +=
                  '<tr>' +
                  `<td>${series[0].data[i][3]}</td>` +
                  `<td>${series[0].data[i][0]}</td>` +
                  `<td>${series[0].data[i][1]}%</td>` +
                  `<td>${series[0].data[i][2]}</td>` +
                  '</tr>'
              }
              table += '</tbody></table>'
              return table
            },
            contentToOption,
          },
        },
      }
      charts.toolbox = toolbox
      updateCharts(charts)
    })
  }, [years, months, division, axis, axisOpt, product, productOpt, cag, cagopt, unit])

  // //  求平YTD均值
  // useEffect(() => {
  //   if (!axisOpt.length) return
  //   if (!productOpt.length) return
  //   getSum1({
  //     fields: mafield,
  //     groups: ['year'],
  //     filters: [
  //       {
  //         field: 'year',
  //         operator: 'IN',
  //         values: years,
  //       },
  //       {
  //         field: 'month',
  //         operator: 'IN',
  //         values: months.map((i) => map.indexOf(i)),
  //       },
  //       {
  //         field: 'product',
  //         operator: 'IN',
  //         values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
  //       },
  //       {
  //         field: 'categorycorp',
  //         operator: 'IN',
  //         values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
  //       },
  //       {
  //         field: 'campaigndashboard',
  //         operator: 'IN',
  //         values: axis.includes('all') ? cagopt.slice(1).map((i) => i.value) : cag,
  //       },
  //     ],
  //   }).then((res) => {
  //     console.log(res)
  //     // const avetext = res.map(i=>(`
  //     // ${i.year} ${mafield[0]}YTD均值：${formatpercent?i[mafield[0]]*100+'%':(i[mafield[0]]).toFixed(2)  };
  //     // ${mafield[1]}YTD均值：${formatpercent?i[mafield[1]]*100+'%'  :(i[mafield[1]]).toFixed(2)  }
  //     // `))
  //     // setAverage(avetext)
  //   })
  // }, [years, months, division, axis, axisOpt, product, productOpt, cag, cagopt])

  // useEffect(() => {
  //   if (!lastres) return
  //   // console.log('2.2 第二个图 unit changge rerender',unit)
  //   const formatdata = formatBarData(lastres, 'product', formatdatafn ? 'p' : valname)
  //   formatdata.stack = false
  //   formatdata.formatpercent = true
  //   updateCharts(getScattersOption(formatdata))
  // }, [unit])

  return (
    // <div className="trend-wrap">
    //   <div ref={trend} style={{ width: '100%', height: '320px' }}></div>
    // </div>
    <div className="trend-wrap" style={{ width: '100%' }}>
      <div className="title">
        <div style={{ width: '100%' }}>{title}</div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          width: '100%',

          height: '460px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
