import React, { useEffect, useState } from 'react'
import { Button, Checkbox, message, Tooltip } from 'antd'
import ReactDOM from 'react-dom'

import './index.less'
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons'
const Modal = (props) => {
  const [target, setTarget] = useState(null)
  const { fields, setFields, defaultOption, myDimensionOption, myIndexOption } = props
  const [checkfields, setCheckFields] = useState([])

  // 当前拖拽对象
  const [oldDragItem, setOldDrageItem] = useState()
  // 是否拖拽
  const [isActive, setIsActive] = useState()
  // 是否拖拽进行
  const [isEnter, setIsEnter] = useState()
  // 拖拽交换对象
  const [newDragItem, setNewDragItem] = useState()
  useEffect(() => {
    setTarget(document.querySelector('body'))
  }, [])
  useEffect(() => {
    if (props.show) {
      let newfields = []
      fields.forEach((element) => {
        let list = element.type === 1 ? myDimensionOption : myIndexOption
        for (let i = 0; i < list.length; i++) {
          if (list[i].value === element.value) {
            newfields.push(list[i])
            break
          }
        }
      })
      setCheckFields(newfields)
    }
  }, [props.show])

  if (!props.show) return null

  const close = () => {
    props.onClose()
  }
  const checkAll = (check) => {
    setCheckFields(check ? myDimensionOption.concat(myIndexOption) : [])
  }
  const onFieldsChange = (val) => {
    setCheckFields(val)
    console.log(checkfields)
  }
  const delCheckedIndex = (val, type) => {
    const list = [...checkfields]
    setCheckFields(
      list.filter((i) => {
        return i.value !== val
      })
    )
  }
  const canael = () => {
    props.onClose()
  }
  const initDefaultOption = () => {
    setCheckFields(defaultOption)
  }
  const OK = () => {
    let checkfields1 = checkfields.filter((it) => {
      return it.type === 1
    })
    let checkfields2 = checkfields.filter((it) => {
      return it.type === 2
    })
    if (checkfields1.length >= 1 && checkfields2.length >= 1) {
      const list = checkfields1.concat(checkfields2)
      setFields(list)
      props.onClose()
    } else {
      message.destroy()
      message.warning('请至少选择一项维度与一项指标!')
    }
  }

  const onDragStart = (value) => {
    setOldDrageItem(value)
    setIsActive(value.value)
  }
  const onDragEnter = (value) => {
    setIsEnter(value.value)
    setNewDragItem(value)
  }

  const onDragEnd = () => {
    if (oldDragItem != newDragItem) {
      const oldIndex = checkfields.indexOf(oldDragItem) //获取当前对象所在数组坐标
      const newIndex = checkfields.indexOf(newDragItem) //获取当前目标对象所在数组坐标
      const newArray = [...checkfields]
      newArray.splice(oldIndex, 1) //删除老节点
      newArray.splice(newIndex, 0, oldDragItem) //增加新节点
      setCheckFields(newArray) //保存拖拽后的数组
      setIsActive(-1) //重置状态
      setIsEnter(-1)
    }
  }
  const modal = (
    <div className="modal">
      <div className="mark" onClick={close}></div>
      <div className="modal-wrap">
        <div className="header">
          <span>自定义报表</span>
          <div onClick={close} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </div>
        </div>
        <div className="content">
          <div className="left">
            <div className="tool">
              <span>可选列</span>
              <div className="check" onClick={() => checkAll(true)}>
                添加所有列
              </div>
            </div>
            <div className="checkBox-panel">
              <Checkbox.Group value={checkfields} onChange={onFieldsChange}>
                <div className="groupTitle">维度</div>
                <div className="item-wrap">
                  {myDimensionOption.map((item) => {
                    return (
                      <div className="item" key={item.value}>
                        <Checkbox
                          key={item.value}
                          checked={checkfields
                            .map((it) => {
                              return it.value
                            })
                            .includes(item.value)}
                          value={item}
                        >
                          {item.label}
                        </Checkbox>

                        <div style={{ display: item.tips ? 'inline-block' : 'none' }}>
                          <Tooltip
                            placement="bottom"
                            title={() => {
                              return (
                                <div>
                                  <div>Wechat渠道定义：Click to Landing</div>
                                  <div>其他渠道定义：Click</div>
                                </div>
                              )
                            }}
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="groupTitle">指标</div>
                <div className="item-wrap">
                  {myIndexOption.map((item) => {
                    return (
                      <div className="item" key={item.value}>
                        <Checkbox key={item.value} value={item}>
                          {item.label}
                        </Checkbox>
                        <div style={{ display: item.tips ? 'inline-block' : 'none' }}>
                          <Tooltip
                            placement="bottom"
                            title={() => {
                              return (
                                <div>
                                  {item.tips.split('|').map((i) => {
                                    return <div>{i}</div>
                                  })}
                                </div>
                              )
                            }}
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Checkbox.Group>
            </div>
          </div>
          <div className="right">
            <div className="tool">
              <span>已选列({checkfields.length})</span>
              <div className="check" onClick={() => checkAll(false)}>
                清空
              </div>
            </div>
            <div className="checkBox-panel" style={{ paddingTop: '18px' }}>
              {[1, 2].map((it_) => {
                return (
                  <div key={it_ + 'div'}>
                    <div className="checktitle">{it_ === 1 ? '维度' : '指标'}：</div>

                    {checkfields
                      .filter((i) => {
                        return i.type === it_
                      })
                      .map((item, index) => {
                        return (
                          <div
                            className="checkedIndex-box"
                            key={item.value}
                            draggable={true}
                            onDragStart={() => {
                              onDragStart(item)
                            }}
                            onDragEnter={() => {
                              onDragEnter(item)
                            }}
                            onDragEnd={() => {
                              onDragEnd()
                            }}
                          >
                            <span
                              style={{
                                width: '80%',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              {item.label}
                            </span>
                            <span
                              className="close"
                              onClick={() => delCheckedIndex(item.value, it_)}
                            >
                              <CloseOutlined />
                            </span>
                          </div>
                        )
                      })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="bottom-bottom">
          <div className="check" onClick={initDefaultOption}>
            恢复默认指标
          </div>
          <div>
            {' '}
            <Button onClick={canael}>取消</Button>
            <Button type="primary" style={{ marginLeft: '24px' }} onClick={() => OK()}>
              确定
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
  return ReactDOM.createPortal(modal, target)
}

const C = (props) => {
  const [show, setShow] = useState(false)
  const { fields, setFields, defaultOption, myDimensionOption, myIndexOption } = props
  const onClose = () => {
    setShow(false)
  }
  return (
    <div>
      <Modal
        show={show}
        onClose={onClose}
        fields={fields}
        setFields={setFields}
        defaultOption={defaultOption}
        myDimensionOption={myDimensionOption}
        myIndexOption={myIndexOption}
      />
      <Button type="link" onClick={() => setShow((i) => !i)}>
        自定义报表
      </Button>
    </div>
  )
}

const MyModal = (props) => {
  const { fields, setFields, defaultOption, myDimensionOption, myIndexOption } = props

  useEffect(() => {
    //  setFields('5566778')
  }, [])
  return (
    <div>
      <C
        fields={fields}
        setFields={setFields}
        defaultOption={defaultOption}
        myDimensionOption={myDimensionOption}
        myIndexOption={myIndexOption}
      />
    </div>
  )
}

export default MyModal
