import Card from '../../components/Card'
import { useNavigate, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { Select } from 'antd'
import { connect } from 'react-redux'
import styles from './index.module.less'

import TrendPerformanceMonth3 from './trendPerformanceMonth3'
const { wrap } = styles
const Searchshare = (props) => {
  const { unit } = props
  const navigate = useNavigate()
  let { state } = useLocation()
  console.log(state, '????------------------')
  // todo 这里先写死  后面redux提供
  const [year, setYear] = useState(state.year || 2023)
  const [month, setMonth] = useState(state.month || 1)
  const [field, setField] = useState(state.field || 'revenue')
  const [channel, setchannel] = useState(state.channel)

  // const options = years.map((i) => ({ label: i, value: i }))
  const keyOpt = {
    revenue: 'Revenue',
    session: 'Session',
  }
  return (
    <div className={wrap}>
      <div className="breadcrumb">
        <span className="root" onClick={() => navigate('/performance/index')}>
          .COM Performance Trend
        </span>{' '}
      </div>
      <div style={{ display: 'flex' }}>
        <Card title={''} style={{ flex: 1 }}>
          <TrendPerformanceMonth3
            title={`.COM ${keyOpt[field] || ''} Trend & Paid Search Share`}
            year={[year]}
            month={[month]}
            channel={channel}
            unit={unit}
            type={field}
            style={{}}
          ></TrendPerformanceMonth3>
        </Card>
      </div>
    </div>
  )
}
export default connect((state) => ({
  unit: state.unit,
}))(Searchshare)
