import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Radio, Select } from 'antd'
import { getPerformanceSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getMonthTrendBarChartsOption2 } from './util'
import { formatNum, numberWithCommas } from '@/common/util'

import { Empty } from 'antd'

export default function Crossbar4(props) {
  const {
    division,
    years,
    unit,
    title,
    category,
    productLine,
    campaignname,
    mediaGroup,
    media,
    mediaformat,
    get,
    months,
    yearsopt,
    setmonths,
    setyears,
    asset,
  } = props
  const [group, setGroup] = useState('divisioncorp')
  const [field, setfield] = useState('cost')
  const radioOption = [
    {
      label: 'Division',
      value: 'divisioncorp',
    },
    {
      label: 'Category',
      value: 'categorycorp',
    },
  ]
  const fieldOpt = [
    {
      label: 'Impression',
      value: 'impression',
      tag: 'all',
    },
    {
      label: 'Click',
      value: 'click',
      tag: 'all',
    },
    {
      label: 'Traffic',
      value: 'traffic',
      tag: 'all',
    },
    {
      label: 'Order',
      value: 'order',
      tag: 'all',
    },
    {
      label: 'Revenue',
      value: 'revenue',
      tag: 'all',
    },
    {
      label: 'Cost',
      value: 'cost',
      tag: 'all',
    },
    {
      label: 'CTR',
      value: 'ctr',
      tag: 'all',
    },
    {
      label: 'Engagement-Wechat',
      value: 'engagementwechat',
      tag: 'Tencent',
    },
    {
      label: 'ER-Wechat',
      value: 'er_wechat',
      tag: 'Tencent',
    },
    {
      label: 'YS T+0 Revenue',
      value: 't0ysrevenue',
      tag: 'Tencent',
    },
    {
      label: 'YS T+7 Revenue',
      value: 't7ysrevenue',
      tag: 'Tencent',
    },
    {
      label: 'YS T+15 Revenue',
      value: 't15ysrevenue',
      tag: 'Tencent',
    },
    {
      label: 'YS T+0 ROI',
      value: 't0ysrevenuema',
      tag: 'Tencent',
    },
    {
      label: 'YS T+7 ROI',
      value: 't7ysrevenuema',
      tag: 'Tencent',
    },
    {
      label: 'YS T+15 ROI',
      value: 't15ysrevenuema',
      tag: 'Tencent',
    },
    {
      label: 'Engagement-POST',
      value: 'engagementpost',
      tag: 'Red',
    },
    {
      label: 'ER-POST',
      value: 'er_post',
      tag: 'Red',
    },
  ]
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  const onChange2 = ({ target: { value } }) => {
    setGroup(value)
  }
  let checkGrouop
  const changePieName = (val) => {
    let ym = val.split('-')
    checkGrouop = val === checkGrouop ? undefined : val

    setyears([checkGrouop ? Number('20' + ym[1]) : yearsopt[0]])
    setmonths([checkGrouop ? ym[0] : 'all'])
  }
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend, { changePieName })
  const map = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  useEffect(() => {
    setfield('cost')
  }, [mediaGroup])
  useEffect(() => {
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Search'],
      },
      {
        field: group,
        operator: 'NOT_IN',
        values: ['', ' ', '-'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i) + 1),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })
    getPerformanceSum({
      title: title + group,
      group,
      fields: [field],
      groups: ['year', 'month', group],
      filters,
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }

      let year = years.includes('all') ? yearsopt : years
      year = year.sort(function (a, b) {
        return a - b
      })
      let opt = res.map((i) => {
        return i[group]
      })
      opt = Array.from(new Set(opt))
      const xAxisData = []
      const ydata = []
      year.forEach((y) => {
        map.forEach((m) => {
          xAxisData.push(m + '-' + (y - 2000))
        })
      })

      opt.forEach((name) => {
        let s_data = []
        year.forEach((y) => {
          let item = res.filter((i) => i[group] === name && i.year === y)

          s_data = s_data.concat(
            map.map((t, i) => {
              let val = item.filter((t2) => {
                return t2.month === t
              })[0]
              return val ? formatNum(val[field], unit, 0) : 0
            })
          )
        })
        ydata.push({ name, data: s_data, year: 1 })
      })

      let d = getMonthTrendBarChartsOption2(
        { xData: xAxisData, yData: ydata },
        unit,
        'month/' + field
      )
      updateCharts(d)
    })
  }, [get, years, months, unit, group, field])

  return (
    <div className="trend-wrap">
      <div className="title">{capitalizeFirstLetter(field) + title}</div>
      <div className="tool">
        <div style={{ width: '170px' }}>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            value={group}
            size="small"
            optionType="button"
          />
        </div>
        <div>
          <Select
            options={fieldOpt.filter((i) => {
              return mediaGroup.length == 1
                ? i.tag === 'all' || i.tag === mediaGroup[0]
                : i.tag === 'all'
            })}
            size="small"
            value={field}
            onChange={(val) => {
              setfield(val)
            }}
            style={{ width: '240px' }}
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          width: 'calc(100%)',
          height: '280px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
