import Card from '../../components/Card'
import { useState } from 'react'
import TrendPieHor from './trend-bar-hor'
import CombineDate from './combineDate'
import TrendDivisionAndMonth from './trendDivisionAndMonth'
import TrendPie from './trend-pie'
import { Select } from 'antd'
import { connect } from 'react-redux'
import styles from './index.module.less'
import { useNavigate } from 'react-router-dom'

import { pieconfig6, pieconfig7, argscreator } from './util'
import { themecolor, themecolor1, themecolor_pie3, themecolor_pie31 } from '@/common/vars'

const { wrap } = styles
const Wfj = (props) => {
  const navigate = useNavigate()
  const { currentYear, preYear, yearsOpt, unit } = props

  // todo 这里先写死  后面redux提供
  const [years, setYears] = useState([currentYear, preYear])
  const [year, setYear] = useState(currentYear)
  const handleDateChange = (val) => {
    console.log(val)
    setYears(val)
  }
  // const sechange1 = () => {
  //   console.log('se1 change')
  // }
  return (
    <div className={wrap}>
      <div className="breadcrumb">
        <span className="root" onClick={() => navigate('/home/index')}>
          Finance Budget
        </span>{' '}
        / <span className="current">Budget by WFJ</span>
      </div>
      <Card
        title="WFJ Trend"
        headerFilter={
          <>
            Selected Period：
            <Select
              getPopupContainer={(n) => n.parentNode}
              style={{ width: '120px' }}
              options={yearsOpt}
              onChange={(val) => setYear(val)}
              defaultValue={year}
            />
          </>
        }
      >
        <TrendDivisionAndMonth
          color={themecolor_pie3}
          year={year}
          unit={unit}
          type="WFJ"
          style={{ width: '100%' }}
        ></TrendDivisionAndMonth>
      </Card>
      <Card
        title="Budget Month-on-Month"
        headerLine={true}
        headerFilter={
          <CombineDate
            onChange={(val) => {
              setYears(val)
            }}
            val={years}
            unit={unit}
          />
        }
        style={{ marginTop: '16px' }}
      >
        <div className="line" style={{ marginBottom: '22px' }}>
          <div className="line-1-left">
            <TrendPie
              title="By Axis"
              color={themecolor_pie3}
              color1={themecolor_pie31}
              years={years}
              unit={unit}
              argsfn={argscreator(pieconfig6)}
            ></TrendPie>
          </div>
          <div className="line-1-right">
            <TrendPie
              title="By Product"
              unit={unit}
              color={themecolor}
              color1={themecolor1}
              years={years}
              argsfn={argscreator(pieconfig7)}
            ></TrendPie>
          </div>
        </div>
      </Card>
    </div>
  )
}
export default connect((state) => ({
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  unit: state.unit,
}))(Wfj)
