import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

// import { getIndustrySum, getIndustryProduct } from '../../api/home'
import { getPerformanceSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendLineOption } from './util'
import { redfeedsum } from '../../api/home'
import { divisionOption } from '@/common/vars'
import { formatNum } from '@/common/util'
import axios from 'axios'
import { Empty } from 'antd'
import { mediaFormatOption } from '@/common/vars'
export default function TrendLineProd(props) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const {
    title,
    unitReady,
    division,
    height,
    color,
    years,
    months,
    cag,
    subcag,
    treeopt,
    barwidth,
    groups,
    srcoll,
    fields,
    argcreator,
    unit,
    dataViewHeaderFirstTitle,
    refreshunit,
    mediaformat,
    style,
    asset,
    formatpercent,
    sortdate,
  } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const [lastres, setlastres] = useState(null)
  const [average, setAverage] = useState([])
  const [average2, setAverage2] = useState([])
  const [average3, setAverage3] = useState([])
  const [average4, setAverage4] = useState([])
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const fn = argcreator
    ? argcreator
    : (res) => {
        const yAxis = [...new Set(res.map((i) => i['campaignname']))] //yname props
        const series = res.reduce((pre, cur) => {
          let name = cur['content'] //seriesname    props
          if (!pre[name]) pre[name] = []
          // debugger
          pre[name].push(cur[fields[0]])
          return pre
        }, {})
        return { yAxis, series, color, dataViewHeaderFirstTitle, barwidth, formatpercent }
      }
  useEffect(() => {
    if (JSON.stringify(treeopt) === '{}') return
    const config = {
      fields,
      title: '折柱图',
      groups,
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: subcag.includes('all') ? treeopt['productline'] : subcag,
        },
        {
          field: 'assets',
          operator: 'IN',
          values: (asset || ['all']).includes('all') ? treeopt['assets'] : asset,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: cag.includes('all') ? treeopt['categorycorp'] : cag,
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: mediaformat.includes('all')
            ? mediaFormatOption.map((it) => {
                return it.value
              })
            : mediaformat,
        },
        // {
        //   field: ' mediaformat',
        //   operator: 'IN',
        //   values: ['GD Feeds', 'RTB Feeds'],
        // },
        {
          field: ' mediagroup ',
          operator: 'IN',
          values: ['Red'],
        },
      ],
    }
    if (sortdate) {
      config.sorts = [{ field: 'startdate', type: 'ASC' }]
    }
    redfeedsum(config).then(async (res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      const xAxisData = res.map((i) => {
        return i.assets
      })
      const pre = 'cost'
      const cur = 'cpe'
      const curdata = res.map((i) => {
        return formatNum(i.cpe || 0, unit, 0)
      })

      const predata = res.map((i) => {
        return formatNum(i.cost || 0, unit, 0)
      })

      updateCharts(getTrendLineOption({ predata, curdata, pre, cur, formatpercent, xAxisData }))
    })
    return () => {}
  }, [years, months, cag, subcag, treeopt, asset, unit, mediaformat]) //division,

  const getAverage = (field, fun) => {
    redfeedsum({
      fields: [field],
      groups: ['year'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: subcag.includes('all') ? treeopt['productline'] : subcag,
        },
        {
          field: 'assets',
          operator: 'IN',
          values: (asset || ['all']).includes('all') ? treeopt['assets'] : asset,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: cag.includes('all') ? treeopt['categorycorp'] : cag,
        },
        // {
        //   field: ' mediaformat',
        //   operator: 'IN',
        //   values: ['GD Feeds', 'RTB Feeds'],
        // },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: mediaformat.includes('all')
            ? mediaFormatOption.map((it) => {
                return it.value
              })
            : mediaformat,
        },
        {
          field: ' mediagroup ',
          operator: 'IN',
          values: ['Red'],
        },
      ],
    }).then((res) => {
      if (!Array.isArray(res) || res.length === 0) return fun([])
      const avetext = res.map(
        (i) =>
          `${i.year} ${field}YTD均值：${formatpercent ? i[field] + '%' : i[field].toFixed(0)}   `
      )
      fun(avetext)
    })
  }
  const getAverage3 = (field, fun) => {
    redfeedsum({
      fields: [field],
      groups: ['year'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: [new Date().getFullYear()],
        },
        {
          field: 'month',
          operator: 'IN',
          values: [1, 2, 3, 4, 5, 6],
        },
        {
          field: 'productline',
          operator: 'IN',
          values: subcag.includes('all') ? treeopt['productline'] : subcag,
        },
        {
          field: 'assets',
          operator: 'IN',
          values: (asset || ['all']).includes('all') ? treeopt['assets'] : asset,
        },
        {
          field: ' mediaformat',
          operator: 'IN',
          values: ['GD Feeds', 'RTB Feeds'],
        },
        {
          field: ' mediagroup ',
          operator: 'IN',
          values: ['Red'],
        },
      ],
    }).then((res) => {
      console.log(res, '///////')
      if (!Array.isArray(res) || res.length === 0) return fun([])
      const avetext = res.map(
        (i) =>
          `${i.year} ${field}1H BenchMark：${
            formatpercent ? i[field] + '%' : i[field].toFixed(0)
          }   `
      )
      fun(avetext)
    })
  }

  // 求平均
  useEffect(() => {
    if (JSON.stringify(treeopt) === '{}') return
    else {
      getAverage('cost', setAverage)
      getAverage('cpe', setAverage2)
      getAverage3('cost', setAverage3)
      getAverage3('cpe', setAverage4)
    }
  }, [years, months, cag, subcag, treeopt, mediaformat])

  return (
    <div className="trend-wrap" style={{ position: 'relative' }}>
      {average.length ? (
        <div className="average">
          {average.join(',')} {average3.join(',')}
        </div>
      ) : null}
      {average2.length ? (
        <div className="average2">
          {average2.join(',')} {average4.join(',')}
        </div>
      ) : null}
      <div className="title">
        <div>{title}</div>
      </div>

      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{ display: empty ? 'none' : 'block', width: '100%', height: '570px' }}
      ></div>
    </div>
  )
}
