import Card from '@/components/Card'
import Filters from './filters'
import Filters1 from './filters1'
import Filters2 from './filters2'
import FiltersWithProd1 from './filtersWithProd1'
import FiltersWithProd2 from './filtersWithProd2'
import { useEffect, useState, createRef } from 'react'
import { connect } from 'react-redux'
import TrendBarHor from './trend-bar-hor'
import TrendBarHorProd from './trend-bar-hor-prod'
import TrendLine from './trend-line'
import TrendLineProd from './trend-line-prod'
import TrendLine1 from './trend-line1'
import TrendLine11 from './trend-line11'
import TrendLine2 from './trend-line2'
import TrendPercent from './trend-percent'
import TrendPerformanceMonth from './trendPerformanceMonth'
import TrendPerformanceMonth2 from './trendPerformanceMonth2'
import { getPerformanceSum } from '@/api/home'
import { Select } from 'antd'
import styles from './index.module.less'
import { Empty } from 'antd'
import { getIndustrySum, getIndustryProduct } from '@/api/home'
import axios from 'axios'

const Industry = (props) => {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const { currentYear, preYear, years, unit } = props
  const { wrap } = styles
  const [initTop10band, setInitTop10band] = useState([])
  const [diffRankMap, setDiffRankMap] = useState({})
  const [curRank, setCurRank] = useState({})
  const [updatetime, setupdatetime] = useState('')
  const [defaultOpt, setDefaultOpt] = useState({
    brandOpt: [],
    divisionOpt: [],
    categoryOpt: [],
  })
  // 最后两个图用
  const [defaultOpt1, setDefaultOpt1] = useState({
    brandOpt: [],
    divisionOpt: [],
    categoryOpt: [],
    prodOpt: [],
  })
  const [val1, setVal1] = useState({
    years: [currentYear, preYear],
    brand: [],
    media: [],
    division: 'FA',
    category: ['FA'],
  })

  const [val4, setVal4] = useState({
    years: [currentYear, preYear],
    category: [],
    brand: [],
    division: 'FA',
    axis: ['FA'],
    product: [],
  })
  const [clickbrand1, setClickbrand1] = useState('')
  const [clickbrand2, setClickbrand2] = useState('')
  const handleFilterChange1 = (type, newval) => {
    setVal1((val) => {
      setClickbrand1('')
      const val1 = { ...val }
      // const val1 = JSON.parse(JSON.stringify(val))
      val1[type] = newval
      return val1
    })
  }

  const handleFilterChange4 = (type, newval) => {
    setVal4((val) => {
      setClickbrand2('')
      const val4 = { ...val }
      val4[type] = newval
      return val4
    })
  }

  const getTop = (division, category, cb) => {
    getIndustrySum({
      fields: ['searchindex'],
      groups: ['year', 'brand'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: [preYear, currentYear],
        },
        {
          field: 'category',
          operator: 'IN',
          values: category,
        },
        {
          field: 'division',
          operator: 'IN',
          values: division,
        },
        {
          field: 'month',
          operator: 'IN',
          values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(
            (i) => i <= new Date().getMonth() + 1
          ),
        },
      ],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
        {
          field: 'searchindex',
          type: 'DESC',
        },
      ],
    }).then((res) => {
      if (!res?.length) return
      // debugger
      const top5 = res
        .filter((i) => i.year === currentYear)
        .slice(0, 5)
        .map((i) => i.brand)
      const top10 = res
        .filter((i) => i.year === currentYear)
        .slice(0, 10)
        .map((i) => i.brand)

      // // 排名变化表 这里是总排名
      const preRank = res
        .filter((i) => i.year === preYear)
        .reduce((pre, cur, curIndex) => {
          pre[cur.brand] = curIndex + 1
          return pre
        }, {})
      const curRank = res
        .filter((i) => i.year === currentYear)
        .reduce((pre, cur, curIndex) => {
          pre[cur.brand] = curIndex + 1
          return pre
        }, {})

      const diffRankMap = {}
      for (let name in curRank) {
        diffRankMap[name] = preRank[name] - curRank[name] //老排名-新排名=当前排名变化
      }
      setDiffRankMap(diffRankMap)

      setCurRank(curRank)
      // console.log(diffRankMap, curRank, preRank)
      // todo 为什么setInitTop10band 不影响sate
      // setInitTop10band(top10)
      // debugger
      // handleFilterChange1('brand', top10)
      // setVal1((val) => ({ ...val, brand: top10 }))
      // setVal2((val) => ({ ...val, brand: top5 }))
      // setVal3((val) => ({ ...val, brand: ['CHANEL'] }))
      // setVal4((val) => ({ ...val, brand: ['CHANEL'] }))
      // cb && cb({ top10, top5 })
      // handleFilterChange2('brand', top5)
      // handleFilterChange3('brand', top5)
      // handleFilterChange4('brand', top5)
      return () => {
        console.log('Operation canceled by the user')
        source.cancel('Operation canceled by the user')
      }
    })
  }

  const getUpdateTime = () => {
    getIndustrySum({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
      sorts: [{ field: 'maxyearandmonth', type: 'DESC' }],
    }).then((res_) => {
      let res = res_.sort((a, b) => {
        return b.year - a.year
      })
      setupdatetime(res[0].maxyearandmonth)
    })
  }
  useEffect(() => {
    getUpdateTime()
    getTop(
      ['Corp'],
      ['Corp']
      // ({ top10, top5 }) => {
      //   setClickbrand1(top10[0])
      //   // handleFilterChange4('division', 'FA')
      //   // handleFilterChange4('category', ['FA'])
      //   // handleFilterChange4('product', prods)
      // }
    )
  }, [])

  const getRank = (division, category, years, media) => {
    getIndustrySum({
      fields: ['searchindex'],
      groups: ['year', 'brand'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'axis',
          operator: 'IN',
          values: category,
        },
        {
          field: 'division',
          operator: 'IN',
          values: division,
        },
        {
          field: 'media',
          operator: 'IN',
          values: media,
        },
        {
          field: 'month',
          operator: 'IN',
          values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(
            (i) => i <= new Date().getMonth() + 1
          ),
        },
      ],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
        {
          field: 'searchindex',
          type: 'DESC',
        },
      ],
    }).then((res) => {
      if (!res?.length) return
      // // 排名变化表 这里是总排名
      // debugger
      const preRank = res
        .filter((i) => i.year === years[1])
        .reduce((pre, cur, curIndex) => {
          pre[cur.brand] = curIndex + 1
          return pre
        }, {})
      const curRank = res
        .filter((i) => i.year === years[0])
        .reduce((pre, cur, curIndex) => {
          pre[cur.brand] = curIndex + 1
          return pre
        }, {})
      const diffRankMap = {}
      for (let name in curRank) {
        diffRankMap[name] = preRank[name] - curRank[name] //老排名-新排名=当前排名变化
      }
      setDiffRankMap(diffRankMap)
      setCurRank(curRank)
      console.log('从新计算了rank')
      console.log(curRank, preRank, diffRankMap)
      return () => {
        console.log('Operation canceled by the user')
        source.cancel('Operation canceled by the user')
      }
    })
  }

  return (
    <>
      <div className={wrap}>
        <div style={{ marginBottom: '12px', position: 'relative' }}>
          <Filters
            getRank={getRank}
            onChange={handleFilterChange1}
            val={val1}
            defaultOpt={defaultOpt}
          />
          <div className="time">Data update to {updatetime}</div>
        </div>
        <Card
          title="Industry Search Index"
          headerLine={true}

          // style={{ marginTop: '16px' }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {
                <TrendBarHor
                  unit={unit}
                  diffRankMap={diffRankMap}
                  curRank={curRank}
                  setClickbrand={setClickbrand1}
                  brand={val1.brand}
                  media={val1.media}
                  years={val1.years}
                  category={val1.category}
                  division={val1.division}
                  title="Brand Search Ranking"
                ></TrendBarHor>
              }
            </div>
            <div style={{ flex: 1, marginLeft: '16px', backgroundColor: '#fefefe' }}>
              {
                <TrendLine
                  unit={unit}
                  brand={clickbrand1}
                  years={val1.years}
                  category={val1.category}
                  division={val1.division}
                  media={val1.media}
                  title={`Brand Search Trend(${clickbrand1})`}
                ></TrendLine>
              }
            </div>
          </div>
        </Card>
        <Card
          title="Brand Product Line Search Index"
          headerLine={true}
          headerFilter={
            <FiltersWithProd2
              unit={unit}
              division={val1.division}
              axis={val1.category}
              brand={val1.brand}
              defaultOpt={defaultOpt1}
              media={val1.media}
              onChange={handleFilterChange4}
              val={val4}
              defaultSelectAllProd
            />
          }
          style={{ marginTop: '16px' }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {
                <TrendBarHorProd
                  unit={unit}
                  media={val1.media}
                  diffRankMap={diffRankMap}
                  curRank={curRank}
                  setClickbrand={setClickbrand2}
                  brand={val1.brand}
                  years={val1.years}
                  category={val4.category}
                  axis={val1.category}
                  division={val1.division}
                  product={val4.product}
                  title="Product Line Search Ranking"
                ></TrendBarHorProd>
              }
            </div>
            <div style={{ flex: 1, marginLeft: '16px', backgroundColor: '#fefefe' }}>
              {
                <TrendLineProd
                  unit={unit}
                  product={clickbrand2}
                  years={val1.years}
                  media={val1.media}
                  category={val4.category}
                  division={val1.division}
                  brand={val1.brand}
                  axis={val1.category}
                  title={`Product Line Search Trend(${clickbrand2})`}
                ></TrendLineProd>
              }
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default connect((state) => ({
  currentYear: state.years[state.years.length - 1], //默认值，当前年份
  preYear: state.years[state.years.length - 2],
  unit: state.unit,
}))(Industry)
