import Card from '../../components/Card'
import { useState } from 'react'
import TrendBarHor from './trend-bar-hor'
import CombineDate from './combineDate'
import TrendDivisionAndYear from './trendDivisionAndYear'
import TrendPie from './trend-pie'
import { Button, Select, message } from 'antd'
import EmptyWrapper from '@/components/EmptyWrapper'
import { connect } from 'react-redux'
import styles from './index.module.less'
import SeSelector from './seSelector'
import { divisionOption } from '@/common/vars'
import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { downTable } from '@/api/home'
import TreeTable from './treeTable'
import { pieconfig1, pieconfig2, pieconfig3, argscreator } from './util'
import {
  themecolor_pie1,
  themecolor_pie11,
  themecolor_se,
  themecolor_se1,
  themecolor,
  themecolor1,
} from '@/common/vars'

const { wrap } = styles
const Home = (props) => {
  const { currentYear, preYear, unit, yearsopt, division } = props
  const [years, setYears] = useState([currentYear, preYear])
  const handleDateChange = (val) => {
    setYears(val)
  }
  const monthOptions = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const [years2, setyears2] = useState([currentYear])
  const [months, setmonths] = useState(['all'])

  const downLoadTable = () => {
    downTable(
      {
        filters: [
          { field: 'year', operator: 'IN', values: years2.includes('all') ? yearsopt : years2 },
          {
            field: 'month',
            operator: 'IN',
            values: months.includes('all')
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
              : months.map((i) => map.indexOf(i)),
          },
          {
            field: 'divisioncorp',
            operator: 'IN',
            values: division.includes('all') ? divisionOption : division,
          },
          {
            field: 'mediagroup',
            operator: 'IN',
            values: ['Baidu', 'Non-Baidu'],
          },
          {
            field: 'mediachannel',
            operator: 'IN',
            values: ['Search'],
          },
          {
            field: 'mediaformat',
            operator: 'IN',
            values: ['Brandzone', 'PPC'],
          },
        ],
        sorts: [
          {
            field: 'mediaformat',
            type: 'ASC',
          },
        ],
      },
      { responseType: 'blob' }
    ).then((res) => {
      const data = res.data
      const link = document.createElement('a')
      const contentType = 'application/vnd.ms-excel'
      const blob = new Blob([data], { type: contentType })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'Paid Search Pivot.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }
  const sechange1 = () => {
    console.log('se1 change')
  }
  const onYearChange = (val) => {
    if (val.length) {
      const index = val.indexOf('all')

      if (index === 0 && val.length > 0) return setyears2(val.slice(1)) //由默认转为其他
      if (index === val.length - 1 && val.length > 0) return setyears2(['all']) //由其他转为默认
      return setyears2(val)
    } else {
      message.info('至少保留一个年份')
    }
  }

  const onMonthChange = (val) => {
    if (val.length) {
      const index = val.indexOf('all')
      if (index === 0 && val.length > 0) return setmonths(val.slice(1)) //由默认转为其他
      if (index === val.length - 1 && val.length > 0) return setmonths(['all']) //由其他转为默认
      setmonths(val)
      //setCheckAll(checkedValues.length === monthOptions.length)
    } else {
      message.info('至少保留一个月份')
    }
  }
  // const [division, setDivision] = useState(divisionOption) //division写死
  // const handleDivisionChange = (val) => {
  //   setDivision(val)
  // }
  const [se, setSe] = useState(['Baidu'])
  return (
    <div className={wrap}>
      <Card title="Annual Budget Trend by Division">
        <EmptyWrapper>
          <TrendDivisionAndYear unit={unit}></TrendDivisionAndYear>
        </EmptyWrapper>
      </Card>
      <Card
        title="Budget Month-on-Month"
        headerLine={true}
        headerFilter={<CombineDate onChange={(years) => setYears(years)} val={years} />}
        style={{ marginTop: '16px' }}
      >
        <div className="line" style={{ marginBottom: '22px' }}>
          <div className="line-1-left">
            <TrendPie
              clickjump={`?from=${years[0]}&to=${years[1]}`}
              title="Budget by Division"
              years={years}
              unit={unit}
              argsfn={argscreator(pieconfig1)}
              color={themecolor_pie1}
              color1={themecolor_pie11}
            ></TrendPie>
          </div>
          <div className="line-1-right">
            {<TrendBarHor years={years} unit={unit} title="Budget by Axis"></TrendBarHor>}
          </div>
        </div>
        <div>
          {/* <div
            className="division-filter"
            style={{ borderTop: '1px solid #F0F0F0', paddingTop: '16px' }}
          >
            <span style={{ marginRight: '8px' }}>Division:</span>{' '}
            <SeSelector onChange={handleDivisionChange}></SeSelector>
          </div> */}
          <div className="line">
            <div className="line-1-left">
              <TrendPie
                setClickbrand={setSe}
                title="Budget by SE"
                years={years}
                unit={unit}
                division={division}
                argsfn={argscreator(pieconfig2)}
                dataViewHeaderFirstTitle="SE"
                color={themecolor_se}
                color1={themecolor_se1}
              ></TrendPie>
            </div>
            <div className="line-1-right">
              <TrendPie
                se={se}
                title={`Budget by ${se}`}
                years={years}
                unit={unit}
                division={division}
                argsfn={argscreator(pieconfig3)}
                dataViewHeaderFirstTitle={se}
                color={themecolor}
                color1={themecolor1}
              ></TrendPie>
            </div>
          </div>
        </div>
      </Card>

      <Card title="Paid Search Pivot" style={{ position: 'relative' }} headerLine={true}>
        <div className="downLoadTable">
          <div>
            <div className="toolLine">
              <span className="label">Year </span>
              <Select
                maxTagCount={3}
                options={[{ label: '全部', value: 'all' }].concat(
                  yearsopt.map((i) => {
                    return { label: i, value: i }
                  })
                )}
                value={years2}
                onChange={onYearChange}
                style={{ width: '240px' }}
                mode={'multiple'}
              />
            </div>
          </div>

          <div className="toolLine">
            <span className="label">Month </span>
            <Select
              maxTagCount={3}
              options={[{ label: '全部', value: 'all' }].concat(
                monthOptions.map((i) => {
                  return { label: i, value: i }
                })
              )}
              value={months}
              onChange={onMonthChange}
              style={{ width: '240px' }}
              mode={'multiple'}
            />
          </div>

          <Button type="text" onClick={downLoadTable}>
            <VerticalAlignBottomOutlined style={{ fontSize: '18px' }} />
          </Button>
        </div>
        <TreeTable
          division={division}
          years={years2}
          yearsopt={yearsopt}
          months={months}
          unit={unit}
        ></TreeTable>
      </Card>
    </div>
  )
}
export default connect((state) => ({
  yearsopt: state.years.slice().reverse(),
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
  unit: state.unit,
  division: state.division,
}))(Home)
