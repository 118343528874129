import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { formatNum } from '@/common/util'
import { wechatsum, feedsumex } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getMonthTrendBarChartsOption_wechat, formatmonth, formatMillion } from './util'
import { set, update } from 'lodash-es'
import { Empty } from 'antd'

export default function Crossbar2(props) {
  const {
    title,
    unitReady,
    division,
    color,
    years,
    months,
    axis,
    axisOpt,
    xname,
    yname,
    unit,
    dataViewHeaderFirstTitle,
    cb,
    refreshunit,
    style,
    hor = false,
    campaigndashboard,
    checkDivision,
  } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const [lastres, setlastres] = useState(null)

  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const fn = (res) => {
    const xAxisData = [...new Set(res.map((i) => i[xname]))]
    const series = res.reduce((pre, cur) => {
      let name = xname
      if (!pre[name]) pre[name] = []
      if (!pre['months']) pre['months'] = []
      // debugger
      pre[name].push(formatNum(cur[yname], unit, 0))
      pre['months'].push(cur['months'] || '--')
      return pre
    }, {})

    return { xAxisData, series, color, dataViewHeaderFirstTitle, hor }
  }
  useEffect(() => {
    if (!axisOpt.length) return
    let filters = [
      {
        field: 'year',
        operator: 'IN',
        values: years,
      },
      {
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      },
      {
        field: 'categorycorp',
        operator: 'IN',
        values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
      },
      {
        field: 'mediagroup',
        operator: 'IN',
        values: ['Tencent'],
      },
      {
        field: 'mediaformat',
        operator: 'IN',
        values: ['Channel Feeds'],
      },
      // {
      //   field: 'division',
      //   operator: 'IN',
      //   values: division === 'all' ? divisionOption : [division],
      // },
    ]

    if (checkDivision) {
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: [checkDivision],
      })
    }
    feedsumex({
      fields: [yname, 'months', 'minmonth'],
      groups: [xname],
      title,
      sorts: [
        {
          field: 'minmonth',
          type: 'DESC',
        },
      ],
      filters,
    }).then((res) => {
      if (!Array.isArray(res) || !res.length) {
        return setEmpty(true)
      }
      setEmpty(false)
      const arr = res.map((i) => {
        return {
          label: i.campaignname,
          value: i.campaignname,
        }
      })
      cb([{ label: '全部', value: 'all' }, ...arr])
      setlastres(res)
      updateCharts(getMonthTrendBarChartsOption_wechat(fn(res)))
    })
  }, [years, months, axis, axisOpt, checkDivision])
  useEffect(() => {
    if (!lastres) return
    const filterres = lastres.filter((i) =>
      campaigndashboard.includes('all') ? true : campaigndashboard.includes(i.campaigndashboard)
    )
    // console.log('2.2 第二个图 unit changge rerender',unit)
    const newopt = getMonthTrendBarChartsOption_wechat(fn(filterres))
    // console.log(newopt)
    updateCharts(newopt)
  }, [unit, campaigndashboard])

  return (
    <div className="trend-wrap">
      <div className="title">
        <div>{title}</div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          width: 'calc(100%)',
          height: '420px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}
